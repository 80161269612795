<template>
  <div id="main-container" @click="CheckIfLayoutOpen">
    <div id="main-layout">
      <MainToolbar />
      <ViewerContent />
    </div>
  </div>
</template>

<script>
import MainToolbar from "../components/ui/MainToolbar.vue";
import ViewerContent from "../components/ViewerContent.vue";

import { useMainStore } from "../store/main";
import { useUIStore } from "../store/ui";
import { useToolsStore } from "../store/tools";
import { initCornerstone3D } from "../../utils/demo/helpers";
import { onMounted } from "vue";
import { useConfigStore } from "../store/config";
import { useRoute } from 'vue-router'

export default {
  name: "Viewer",
  components: {
    MainToolbar,
    ViewerContent,
  },
  setup() {
    const toolsStore = useToolsStore();
    toolsStore.SetupToolGroups();
    const UIstore = useUIStore();
    const configStore = useConfigStore();
    const route = useRoute();

    const dropdown = document.getElementsByClassName("hideWhenCtrl");
    const hideOnCtrl = () => {
      for (let i = 0; i < dropdown.length; i++) {
        dropdown[i].style.display = "none";
      }
    };

    const showOnCtrl = () => {
      for (let i = 0; i < dropdown.length; i++) {
        dropdown[i].style.display = "block";
      }
    };

    // if hiding on ctrl enabled then hide thumbnail sizing dropdown and add event listeners
    if (configStore.thumbnailsDefault.enabled) {
      if (configStore.thumbnailsDefault.sizeOnCtrl) {
        hideOnCtrl();
        document.body.addEventListener("keydown", (event) => {
          if (event.key == "Control") {
            showOnCtrl();
          }
        });
        document.body.addEventListener("keyup", (event) => {
          if (event.key == "Control") {
            hideOnCtrl();
          }
        });
      }
    }

    onMounted(async () => {
      //init cornerstone
      await initCornerstone3D();
      var studyUID = route.params.studyUID;
      var centerName = route.params.centername;
      UIstore.centerName = centerName;

      const store = useMainStore();
      store.loadStudyFromServer(studyUID);
    });

    return {
      hideOnCtrl,
      showOnCtrl,
      dropdown,
    };
  },
  methods: {
    GetURLParam(name, url = window.location.href) {},
    CheckIfMobile() {
      const store = useUIStore();
      store.Expand();
    },
    CheckIfLayoutOpen() {
      const UIstore = useUIStore();
      var target = event.target;
      if (!target.closest(".layout-check")) {
        UIstore.showLayoutChanger = false;
      }
    },
  },
};
</script>

<style scoped>
#main-container {
  height: 100%;
}

#main-layout {
  height: 100%;
  color: #444;
  overflow: hidden;
}
</style>
