<template>
    <div id="toolbar">
        <CompanyLogo class="hide-when-protrait" />

        <div v-if="vrMode" class="toolbar-section vrPresets">
            <VRPresets></VRPresets>
        </div>

        <div id="layout" class="layout-check toolbar-section">
            <div id="layout-icon" @click="ShowLayout" class="layout-check" :class="{ inactive: vrMode || mprMode }">
                <svg class="layout-svg" fill="#d4d4d4" color="#d4d4d4" width="22" height="22"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
                    <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="1.5">
                        <path fill="none"
                            d="M9.313 8.52a.793.793 0 0 1-.792.793H2.188a.792.792 0 0 1-.792-.792V2.188c0-.438.354-.792.791-.792h6.334c.437 0 .792.354.792.791v6.334z">
                        </path>
                        <path
                            d="M19.604 8.52a.792.792 0 0 1-.791.793h-6.334a.792.792 0 0 1-.791-.792V2.188c0-.438.354-.792.791-.792h6.334a.79.79 0 0 1 .791.791v6.334zM9.313 18.813a.792.792 0 0 1-.792.791H2.188a.791.791 0 0 1-.792-.791v-6.334a.79.79 0 0 1 .791-.791h6.334c.437 0 .792.354.792.791v6.334zm10.291 0a.79.79 0 0 1-.791.791h-6.334a.79.79 0 0 1-.791-.791v-6.334a.79.79 0 0 1 .791-.791h6.334a.79.79 0 0 1 .791.791v6.334z">
                        </path>
                    </g>
                </svg>
                <div class="svg-tooltip">Layout</div>
            </div>
            
            <div class="divider"></div>

            <div id="layout-hover" v-if="showChanger" class="layout-check">
                <div id="box-1" class="box box-1" row="1" col="1" @click="ChangeLayout" @mouseover="BoxHover"
                    @mouseleave="RemoveHover">
                </div>
                <div v-for="box in boxes" :key="box.row + '-' + box.col" :class="['box', 'box-' + box.index]" :row="box.row"
                    :col="box.col" @click="ChangeLayout" @mouseover="BoxHover" @mouseleave="RemoveHover">
                </div>
            </div>

        </div>
        <div class="space"></div>
        <div id="active-tools" style="display:none;">
            <div id="active-left" class="active-tool">
                <svg style="position:absolute;top:10px;left:18px" width="20" height="20" xmlns="http://www.w3.org/2000/svg"
                    shape-rendering="geometricPrecision" text-rendering="geometricPrecision"
                    image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 350 512.41">
                    <g fill-rule="nonzero">
                        <path
                            d="M332.21 242.76h-121.9v-33.87c9.96-3.66 17.16-13.27 17.16-24.48v-37.72c0-11.21-7.18-20.83-17.16-24.49V72.94h8.79c31.1 0 59.38 12.73 79.88 33.23 20.5 20.5 33.23 48.78 33.23 79.88v56.71zm-139.7-120.55c-9.99 3.67-17.16 13.31-17.16 24.48v37.72c0 11.16 7.2 20.8 17.16 24.48v33.87H71.37l-.76.04v-56.75c0-31.1 12.73-59.38 33.24-79.88 20.5-20.5 48.78-33.23 79.87-33.23h8.79v49.27zM78.2 57.13 42.13 21.05c-4.74-4.73-12.42-4.73-17.15 0-4.74 4.74-4.73 12.42 0 17.16l35.7 35.69c5.18-6.23 11.07-11.86 17.52-16.77zm52.84-21.18L116.96 6.3c-3.2-5.86-10.55-8.02-16.4-4.82-5.87 3.2-8.03 10.55-4.83 16.41l12.18 23.28 23.13-5.22zm-93.85 89.42a106.14 106.14 0 0 1 6.4-22.97l-25.7-13.86c-5.86-3.21-13.21-1.04-16.41 4.82-3.2 5.86-1.04 13.21 4.82 16.4l30.89 15.61zm33.42 135.15.76.04h260.84v120.95c0 31.1-12.73 59.38-33.23 79.88-20.5 20.5-48.78 33.23-79.88 33.23h-35.38c-31.09 0-59.37-12.73-79.87-33.23-20.51-20.5-33.24-48.78-33.24-79.88V260.52zm113.11 251.89h35.38c36 0 68.74-14.72 92.46-38.44S350 417.52 350 381.51V186.05c0-36.01-14.72-68.74-38.44-92.46S255.1 55.15 219.1 55.15h-35.38c-36.01 0-68.74 14.73-92.45 38.44-23.72 23.72-38.45 56.45-38.45 92.46v195.46c0 36.01 14.73 68.74 38.45 92.46 23.71 23.72 56.44 38.44 92.45 38.44z" />
                        <path fill="#7596c9"
                            d="M192.51 72.94v49.27c-9.99 3.67-17.16 13.31-17.16 24.48v37.73c0 11.16 7.2 20.79 17.16 24.46v33.88H71.37l-.76.04v-56.75c0-31.1 12.73-59.38 33.24-79.88 20.5-20.5 48.78-33.23 79.87-33.23h8.79z" />
                    </g>
                </svg>
            </div>
            <div id="active-middle" class="active-tool">
                <svg style="position:absolute;top:10px;left:18px" width="20" height="20" xmlns="http://www.w3.org/2000/svg"
                    shape-rendering="geometricPrecision" text-rendering="geometricPrecision"
                    image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 284 511.24">
                    <path fill-rule="nonzero"
                        d="M125.1 494.24h33.8c29.72 0 56.74-12.17 76.34-31.76 19.59-19.59 31.76-46.62 31.76-76.34V270.52l-.73.03H17v115.59c0 29.72 12.17 56.75 31.76 76.34 19.59 19.59 46.62 31.76 76.34 31.76zm9.35-201.59c0-4.17 3.38-7.55 7.56-7.55a7.55 7.55 0 0 1 7.55 7.55v24.61l6.74-7.05c2.86-3.01 7.64-3.13 10.65-.26 3.01 2.86 3.13 7.64.26 10.65l-19.37 20.29a7.533 7.533 0 0 1-5.83 2.76 7.57 7.57 0 0 1-5.84-2.76L116.8 320.6c-2.87-3.01-2.75-7.79.26-10.65 3.01-2.87 7.79-2.75 10.65.26l6.74 7.06v-24.62zM149.56 51a7.55 7.55 0 0 1-7.55 7.55c-4.18 0-7.56-3.38-7.56-7.55V26.38l-6.74 7.06c-2.86 3.01-7.64 3.13-10.65.26-3.01-2.86-3.13-7.64-.26-10.65l19.37-20.29A7.537 7.537 0 0 1 142.01 0c2.35 0 4.45 1.07 5.83 2.76l19.37 20.29c2.87 3.01 2.75 7.79-.26 10.65-3.01 2.87-7.79 2.75-10.65-.26l-6.74-7.06V51zm.94 74.63c4.76 1.43 9.02 4.04 12.45 7.46 5.37 5.37 8.72 12.8 8.72 20.94v37.14c0 8.13-3.36 15.56-8.73 20.93a29.867 29.867 0 0 1-12.44 7.47v33.98h115.77l.73.03v-54.23c0-29.72-12.17-56.75-31.76-76.34-19.6-19.59-46.62-31.76-76.34-31.76h-8.4v34.38zm-17 93.93c-4.73-1.43-8.98-4.03-12.41-7.45-5.4-5.37-8.75-12.78-8.75-20.94v-37.14c0-8.16 3.34-15.58 8.71-20.95a29.86 29.86 0 0 1 12.45-7.46V91.25h-8.4c-29.72 0-56.75 12.17-76.34 31.76C29.17 142.6 17 169.63 17 199.35v54.2h116.5v-33.99zm25.4 291.68h-33.8c-34.41 0-65.69-14.07-88.36-36.74C14.07 451.84 0 420.55 0 386.14V199.35c0-34.41 14.07-65.69 36.74-88.36s53.95-36.74 88.36-36.74h33.8c34.42 0 65.7 14.08 88.36 36.74 22.67 22.67 36.74 53.95 36.74 88.36v186.79c0 34.41-14.07 65.7-36.74 88.36-22.66 22.66-53.94 36.74-88.36 36.74z" />
                    <path fill="#7596c9"
                        d="M142 133.52h.01c11.28 0 20.51 9.29 20.51 20.52v37.13c0 11.23-9.28 20.52-20.51 20.52H142c-11.23 0-20.52-9.24-20.52-20.52v-37.13c0-11.29 9.24-20.52 20.52-20.52z" />
                </svg>
            </div>
            <div id="active-right" class="active-tool">
                <svg style="position:absolute;top:10px;left:18px" width="20" height="20" xmlns="http://www.w3.org/2000/svg"
                    shape-rendering="geometricPrecision" text-rendering="geometricPrecision"
                    image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 350 512.41">
                    <g fill-rule="nonzero">
                        <path
                            d="M17.79 242.76h121.9v-33.87c-9.96-3.66-17.16-13.27-17.16-24.48v-37.72c0-11.21 7.18-20.83 17.16-24.49V72.94h-8.79c-31.1 0-59.38 12.73-79.88 33.23-20.5 20.5-33.23 48.78-33.23 79.88v56.71zm139.7-120.55c9.99 3.67 17.16 13.31 17.16 24.48v37.72c0 11.16-7.2 20.8-17.16 24.47v33.88h121.14l.75.04v-56.75c0-31.1-12.73-59.38-33.23-79.88-20.5-20.5-48.78-33.23-79.87-33.23h-8.79v49.27zM271.8 57.13l36.07-36.08c4.74-4.73 12.42-4.73 17.15 0 4.74 4.74 4.73 12.42 0 17.16l-35.7 35.69a107.783 107.783 0 0 0-17.52-16.77zm-52.84-21.19L233.04 6.3c3.2-5.86 10.55-8.02 16.4-4.82 5.87 3.2 8.03 10.54 4.83 16.41l-12.18 23.28-23.13-5.23zm93.85 89.43a106.14 106.14 0 0 0-6.4-22.97l25.7-13.86c5.86-3.21 13.21-1.04 16.41 4.82 3.2 5.86 1.04 13.21-4.82 16.4l-30.89 15.61zm-33.43 135.15-.75.04H17.79v120.95c0 31.1 12.73 59.38 33.23 79.88 20.5 20.5 48.78 33.23 79.88 33.23h35.38c31.09 0 59.37-12.73 79.87-33.23 20.5-20.5 33.23-48.78 33.23-79.88V260.52zm-113.1 251.89H130.9c-36 0-68.74-14.72-92.46-38.44S0 417.51 0 381.51V186.05c0-36.01 14.72-68.74 38.44-92.46S94.9 55.15 130.9 55.15h35.38c36.01 0 68.74 14.73 92.45 38.44 23.72 23.72 38.45 56.45 38.45 92.46v195.46c0 36-14.73 68.74-38.45 92.46-23.71 23.71-56.44 38.44-92.45 38.44z" />
                        <path fill="#7596c9"
                            d="M157.49 72.94v49.27c9.99 3.67 17.16 13.31 17.16 24.48v37.72c0 11.16-7.2 20.8-17.16 24.47v33.88h121.14l.75.04v-56.75c0-31.1-12.73-59.38-33.23-79.88-20.5-20.5-48.78-33.23-79.87-33.23h-8.79z" />
                    </g>
                </svg>
            </div>
        </div>
        <div v-for="(section, index) in this.Sectionss" v-bind:key="index" :class="getToolClasses(index)">
            <ToolbarSections v-bind:sectionData="section" />
        </div>

        <!-- <div id="toggleThumbailLocation">
            <button @click="toggleThumbLocation">toggle</button>
        </div> -->



        <div id="end-space" v-if="showEndSpace">
            <div class="nav-container">
                <ul class="nav-items">
                    <!-- Dropdown menu -->
                    <li class="nav-item nav-item-dropdown">
                        <a class="dropdown-trigger" href="#"></a>
                        <ul class="dropdown-menu" id="related-studies" v-if="this.RelatedStudies.length > 0">
                            <li v-for="(study, index) in this.RelatedStudies" v-bind:key="index" class="dropdown-menu-item"
                                @click="this.OpenRelatedStudy()">
                                <a href="#" v-bind:studyUID=study.studyUID>Description: {{ study.studyDescription }} Date:
                                    {{ ConvertDate(study.studyDate) }}</a>
                            </li>
                        </ul>
                        <ul class="dropdown-menu" id="related-studies" v-if="this.RelatedStudies.length <= 0">
                            <li class="dropdown-menu-item no-related">
                                <a>No related studies found</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>

    </div>
</template>

<script>
import CompanyLogo from './CompanyLogo.vue';
import ToolbarSections from './ToolbarSections.vue';
import { useUIStore } from '../../store/ui';
import { useMainStore } from '../../store/main';
import { useToolsStore } from '../../store/tools';
import { useIconStore } from '../../store/icons';
import { windowLevel } from '@cornerstonejs/core/dist/esm/utilities';
import VRPresets from './VRPresets.vue';
import { computed, watch } from 'vue';
import { useEventsStore } from '../../store/events';

export default {
    name: 'MainToolbar',
    components: {
        CompanyLogo,
        ToolbarSections,
        VRPresets,
    },
    data() {
        return {
            showEndSpace: false,
        }
    },
    computed: {
        RelatedStudies() {
            const mainStore = useMainStore();
            var studies = mainStore.relatedStudies;
            return studies;
        },
        Expanded() {
            const store = useUIStore();
            return store.expanded;
        },
        Sectionss() {
            return [...this.sections];
        },
        showChanger() {
            const store = useUIStore();

            return store.showLayoutChanger;
        },
        vrMode() {
            const mainStore = useMainStore();

            return mainStore.vrMode;
        }
    },
    setup(props) {
        // pinia stores
        const iconStore = useIconStore();
        const uiStore = useUIStore();
        const eventStore = useEventsStore();

        //Toolbar sections
        const sections = iconStore.sections;

        // check screen orientation
        let boxes;
        if (window.matchMedia("(orientation: landscape)").matches) {
            boxes = iconStore.boxesLandscape;
        } else {
            boxes = iconStore.boxesPortrait;
        }
        // computed variables 
        let mprMode = computed(() => {
            const mainStore = useMainStore();
            return mainStore.mprMode;
        });

        // watch(mprMode, (newValue) => {
        //     const svgElements = document.querySelectorAll("svg");

        //     svgElements.forEach(svg => {
        //         const tooltip = svg.getAttribute("tooltip");
        //         if (!mprMode.value) {
        //             switch (tooltip) {
        //                 case "MPR W/L":
        //                     svg.classList.add('inactive');
        //                     break;
        //                 case "Crosshairs":
        //                     svg.classList.add('inactive');
        //                     break;
        //             }
        //         } else {
        //             switch (tooltip) {
        //                 case "MPR W/L":
        //                     svg.classList.remove('inactive');
        //                     break;
        //                 case "Crosshairs":
        //                     svg.classList.remove('inactive');
        //                     break;
        //             }
        //         }


        //     });
        // });

        const selectAll = () => {
            eventStore.multiSelectAll();
        }

        return {
            sections,
            boxes,
            mprMode,
            selectAll,
        }
    },
    mounted() {
        window.addEventListener('resize', () => {
            const iconStore = useIconStore();
            const store = useUIStore();
            const mainStore = useMainStore();

            let showChangerState = this.showChanger;
            store.showLayoutChanger = false;
            if (window.matchMedia("(orientation: landscape)").matches) {
                this.boxes = iconStore.boxesLandscape;
            } else {
                this.boxes = iconStore.boxesPortrait;
            }
            store.showLayoutChanger = showChangerState;

            store.SetLayoutRowCol(mainStore.currentLayout);
        });
    },
    methods: {
        toggleThumbLocation() {
            const uiStore = useUIStore();
            uiStore.thumbnailVertical = !uiStore.thumbnailVertical;
        },
        getToolClasses(index) {
            const mainStore = useMainStore();
            let vrMode = mainStore.vrMode;

            const classes = {
                'toolbar-section': true,
                //'hide-on-small-screen': this.hideOnSmallScreen(index),
            }

            classes[`tool-${index + 1}`] = true;

            //When in vr mode hide mpr tool
            let mprTool = classes['tool-5'];
            if (vrMode && mprTool) {
                classes['hide'] = true;
            }

            //Decativate tools tools when in vr mode
            let vrTool = classes['tool-6'];
            if (vrMode && !vrTool) {
                classes['inactive'] = true;
            }



            //Deativate tools when in mpr mode
            if (this.mprMode && !mprTool) {
                classes['inactive'] = true;
            }

            return classes;
        },
        hideOnSmallScreen(index) {
            return index > 3;
        },
        OpenRelatedStudy() {
            var studyUID = event.target.attributes.studyUID.value;
            var thisURL = document.URL;
            var newURL = thisURL.substring(0, thisURL.indexOf("studyUID=") + 9) + studyUID;
            window.open(newURL, '_blank').focus();
        },
        ConvertDate(date) {
            var year = date.substring(0, 4);
            var month = date.substring(4, 6);
            var day = date.substring(6, 8);

            return `${month}-${day}-${year}`;
        },
        ShowLayout() {
            const store = useUIStore();
            if (store.showLayoutChanger == false) {
                store.showLayoutChanger = true;
            } else {
                store.showLayoutChanger = false;
            }
        },
        ChangeLayout() {
            const store = useMainStore();
            var rowCol = event.target.attributes.row.value + "x" + event.target.attributes.col.value;
            store.SetLayout(rowCol);

            var toolsStore = useToolsStore();
            // toolsStore.DeactivateRefLines();
        },
        ChangeLayoutSpecific(row, col) {
            const store = useMainStore();
            var rowCol = row + "x" + col;
            store.SetLayout(rowCol);
        },
        BoxHover() {
            const uiStore = useUIStore();
            var allBoxes = event.target.closest("#layout-hover").children;
            var row = parseInt(event.target.attributes.row.value);
            var col = parseInt(event.target.attributes.col.value);
            if (window.matchMedia("(orientation: landscape)").matches) {
                if (row == 1 && col == 1) {
                    //nothing as it is the only one that needs highlighting
                } else if (row == 1 && col == 2) {
                    allBoxes[0].classList.add("box-hover");
                } else if (row == 2 && col == 1) {
                    allBoxes[0].classList.add("box-hover");
                } else if (row == 2 && col == 2) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                } else if (row == 3 && col == 1) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                } else if (row == 3 && col == 2) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[16].classList.add("box-hover");
                } else if (row == 3 && col == 3) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[2].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[10].classList.add("box-hover");
                    allBoxes[16].classList.add("box-hover");
                    allBoxes[17].classList.add("box-hover");
                } else if (row == 1 && col == 3) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                } else if (row == 2 && col == 3) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[2].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                } else if (row == 4 && col == 1) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[16].classList.add("box-hover");
                } else if (row == 4 && col == 2) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[16].classList.add("box-hover");
                    allBoxes[17].classList.add("box-hover");
                    allBoxes[24].classList.add("box-hover");
                } else if (row == 4 && col == 3) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[2].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[10].classList.add("box-hover");
                    allBoxes[16].classList.add("box-hover");
                    allBoxes[17].classList.add("box-hover");
                    allBoxes[18].classList.add("box-hover");
                    allBoxes[24].classList.add("box-hover");
                    allBoxes[25].classList.add("box-hover");
                } else if (row == 1 && col == 4) {
                    for (let i = 0; i < 3; i++) {
                        allBoxes[i].classList.add("box-hover");
                    }
                } else if (row == 1 && col == 5) {
                    for (let i = 0; i < 4; i++) {
                        allBoxes[i].classList.add("box-hover");
                    }
                } else if (row == 1 && col == 6) {
                    for (let i = 0; i < 5; i++) {
                        allBoxes[i].classList.add("box-hover");
                    }
                } else if (row == 1 && col == 7) {
                    for (let i = 0; i < 6; i++) {
                        allBoxes[i].classList.add("box-hover");
                    }
                } else if (row == 1 && col == 8) {
                    for (let i = 0; i < 7; i++) {
                        allBoxes[i].classList.add("box-hover");
                    }
                } else if (row == 2 && col == 4) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[2].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[10].classList.add("box-hover");
                } else if (row == 2 && col == 5) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[2].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[4].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[10].classList.add("box-hover");
                    allBoxes[11].classList.add("box-hover");
                } else if (row == 2 && col == 6) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[2].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[4].classList.add("box-hover");
                    allBoxes[5].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[10].classList.add("box-hover");
                    allBoxes[11].classList.add("box-hover");
                    allBoxes[12].classList.add("box-hover");
                } else if (row == 2 && col == 7) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[2].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[4].classList.add("box-hover");
                    allBoxes[5].classList.add("box-hover");
                    allBoxes[6].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[10].classList.add("box-hover");
                    allBoxes[11].classList.add("box-hover");
                    allBoxes[12].classList.add("box-hover");
                    allBoxes[13].classList.add("box-hover");
                } else if (row == 2 && col == 8) {
                    for (let i = 0; i < 15; i++) {
                        allBoxes[i].classList.add("box-hover");
                    }
                } else if (row == 3 && col == 4) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[2].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[10].classList.add("box-hover");
                    allBoxes[11].classList.add("box-hover");
                    allBoxes[16].classList.add("box-hover");
                    allBoxes[17].classList.add("box-hover");
                    allBoxes[18].classList.add("box-hover");
                } else if (row == 4 && col == 4) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[2].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[10].classList.add("box-hover");
                    allBoxes[11].classList.add("box-hover");
                    allBoxes[16].classList.add("box-hover");
                    allBoxes[17].classList.add("box-hover");
                    allBoxes[18].classList.add("box-hover");
                    allBoxes[19].classList.add("box-hover");
                    allBoxes[24].classList.add("box-hover");
                    allBoxes[25].classList.add("box-hover");
                    allBoxes[26].classList.add("box-hover");
                } else if (row == 3 && col == 5) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[2].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[4].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[10].classList.add("box-hover");
                    allBoxes[11].classList.add("box-hover");
                    allBoxes[12].classList.add("box-hover");
                    allBoxes[16].classList.add("box-hover");
                    allBoxes[17].classList.add("box-hover");
                    allBoxes[18].classList.add("box-hover");
                    allBoxes[19].classList.add("box-hover");
                } else if (row == 3 && col == 6) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[2].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[4].classList.add("box-hover");
                    allBoxes[5].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[10].classList.add("box-hover");
                    allBoxes[11].classList.add("box-hover");
                    allBoxes[12].classList.add("box-hover");
                    allBoxes[13].classList.add("box-hover");
                    allBoxes[16].classList.add("box-hover");
                    allBoxes[17].classList.add("box-hover");
                    allBoxes[18].classList.add("box-hover");
                    allBoxes[19].classList.add("box-hover");
                    allBoxes[20].classList.add("box-hover");
                } else if (row == 3 && col == 7) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[2].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[4].classList.add("box-hover");
                    allBoxes[5].classList.add("box-hover");
                    allBoxes[6].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[10].classList.add("box-hover");
                    allBoxes[11].classList.add("box-hover");
                    allBoxes[12].classList.add("box-hover");
                    allBoxes[13].classList.add("box-hover");
                    allBoxes[14].classList.add("box-hover");
                    allBoxes[16].classList.add("box-hover");
                    allBoxes[17].classList.add("box-hover");
                    allBoxes[18].classList.add("box-hover");
                    allBoxes[19].classList.add("box-hover");
                    allBoxes[20].classList.add("box-hover");
                    allBoxes[21].classList.add("box-hover");
                } else if (row == 3 && col == 8) {
                    allBoxes[0].classList.add("box-hover");
                    for (let i = 0; i < 23; i++) {
                        allBoxes[i].classList.add("box-hover");
                    }
                } else if (row == 4 && col == 5) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[2].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[4].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[10].classList.add("box-hover");
                    allBoxes[11].classList.add("box-hover");
                    allBoxes[12].classList.add("box-hover");
                    allBoxes[16].classList.add("box-hover");
                    allBoxes[17].classList.add("box-hover");
                    allBoxes[18].classList.add("box-hover");
                    allBoxes[19].classList.add("box-hover");
                    allBoxes[20].classList.add("box-hover");
                    allBoxes[24].classList.add("box-hover");
                    allBoxes[25].classList.add("box-hover");
                    allBoxes[26].classList.add("box-hover");
                    allBoxes[27].classList.add("box-hover");
                } else if (row == 4 && col == 6) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[2].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[4].classList.add("box-hover");
                    allBoxes[5].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[10].classList.add("box-hover");
                    allBoxes[11].classList.add("box-hover");
                    allBoxes[12].classList.add("box-hover");
                    allBoxes[13].classList.add("box-hover");
                    allBoxes[16].classList.add("box-hover");
                    allBoxes[17].classList.add("box-hover");
                    allBoxes[18].classList.add("box-hover");
                    allBoxes[19].classList.add("box-hover");
                    allBoxes[20].classList.add("box-hover");
                    allBoxes[21].classList.add("box-hover");
                    allBoxes[24].classList.add("box-hover");
                    allBoxes[25].classList.add("box-hover");
                    allBoxes[26].classList.add("box-hover");
                    allBoxes[27].classList.add("box-hover");
                    allBoxes[28].classList.add("box-hover");
                } else if (row == 4 && col == 7) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[2].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[4].classList.add("box-hover");
                    allBoxes[5].classList.add("box-hover");
                    allBoxes[6].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[10].classList.add("box-hover");
                    allBoxes[11].classList.add("box-hover");
                    allBoxes[12].classList.add("box-hover");
                    allBoxes[13].classList.add("box-hover");
                    allBoxes[14].classList.add("box-hover");
                    allBoxes[16].classList.add("box-hover");
                    allBoxes[17].classList.add("box-hover");
                    allBoxes[18].classList.add("box-hover");
                    allBoxes[19].classList.add("box-hover");
                    allBoxes[20].classList.add("box-hover");
                    allBoxes[21].classList.add("box-hover");
                    allBoxes[22].classList.add("box-hover");
                    allBoxes[24].classList.add("box-hover");
                    allBoxes[25].classList.add("box-hover");
                    allBoxes[26].classList.add("box-hover");
                    allBoxes[27].classList.add("box-hover");
                    allBoxes[28].classList.add("box-hover");
                    allBoxes[29].classList.add("box-hover");
                } else if (row == 4 && col == 8) {
                    for (let i = 0; i < 31; i++) {
                        allBoxes[i].classList.add("box-hover");
                    }
                }
            }
            else {
                if (row == 1 && col == 2) {
                    allBoxes[0].classList.add("box-hover");
                } else if (row == 1 && col == 3) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                } else if (row == 2 && col == 1) {
                    allBoxes[0].classList.add("box-hover");
                } else if (row == 2 && col == 2) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                } else if (row == 2 && col == 3) {
                    for (let i = 0; i < 5; i++) {
                        allBoxes[i].classList.add("box-hover");
                    }
                } else if (row == 3 && col == 1) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                } else if (row == 3 && col == 2) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[4].classList.add("box-hover");
                    allBoxes[6].classList.add("box-hover");
                } else if (row == 3 && col == 3) {
                    for (let i = 0; i < 8; i++) {
                        allBoxes[i].classList.add("box-hover");
                    }
                } else if (row == 4 && col == 1) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[6].classList.add("box-hover");
                } else if (row == 4 && col == 2) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[4].classList.add("box-hover");
                    allBoxes[6].classList.add("box-hover");
                    allBoxes[7].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                } else if (row == 4 && col == 3) {
                    for (let i = 0; i < 11; i++) {
                        allBoxes[i].classList.add("box-hover");
                    }
                } else if (row == 5 && col == 1) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[6].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                } else if (row == 5 && col == 2) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[4].classList.add("box-hover");
                    allBoxes[6].classList.add("box-hover");
                    allBoxes[7].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[10].classList.add("box-hover");
                    allBoxes[12].classList.add("box-hover");
                } else if (row == 5 && col == 3) {
                    for (let i = 0; i < 15; i++) {
                        allBoxes[i].classList.add("box-hover");
                    }
                }

            }
        },
        RemoveHover() {
            var allBoxes = event.target.closest("#layout-hover").children;
            for (let index = 0; index < allBoxes.length; index++) {
                const element = allBoxes[index];
                element.classList.remove("box-hover");
            }
        }

    }

}
</script>

<style scoped>
#toolbar {
    background-color: #162a4a;
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 5;
    border-bottom: 1px solid #73777e;

    display: grid;
    grid-template-columns: 240px 100px 210px 260px 350px auto auto auto auto auto;
    grid-template-rows: 10px 30px 10px;
    align-items: center;
    text-align: center;
}

@media screen and (max-width: 1680px) {
    #toolbar {
        grid-template-columns: 240px 80px auto auto auto auto auto auto auto auto;
    }
}

.toolbar-section {
    justify-content: left;
    padding-left: 2px;
    padding-right: 2px;
}

.toolbar-section:not(:last-child) {
    border-right: #76eafd8c 1px solid;
}

/* Explicitly place each tool in the respective cell */
.hide-when-protrait {
    grid-column: 1;
    grid-row: 2;
}

.layout-check {
    grid-column: 2;
    grid-row: 2;
}

div.toolbar-section.tool-1 {
    grid-column: 3;
    grid-row: 2;

}

div.toolbar-section.tool-2 {
    grid-column: 4;
    grid-row: 2;
}

div.toolbar-section.tool-3 {
    grid-column: 5;
    grid-row: 2;
}

div.toolbar-section.tool-4 {
    grid-column: 6;
    grid-row: 2;
}

div.toolbar-section.tool-5 {
    grid-column: 7;
    grid-row: 2;
}

.vrPresets {
    grid-column: 7;
    grid-row: 2;
}

div.toolbar-section.tool-6 {
    grid-column: 8;
    grid-row: 2;
    border-right: none;
}

#end-space {
    grid-column: 9;
    grid-row: 2;
}

#toggleThumbailLocation {
    grid-column: 9;
    grid-row: 2;
}

@media screen and (orientation: portrait) {
    .hide-when-protrait {
        display: none;
    }

    #toolbar {
        grid-row-start: 1;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 4;
        border-bottom: 1px solid #73777e;

        display: grid;
        grid-template-columns: auto 0px auto auto auto;
        grid-template-rows: 10px 25px 10px 25px 10px;
        align-items: center;
        text-align: center;
    }

    .toolbar-section {
        justify-content: left;
        padding-left: 15px;
    }

    .toolbar-section:not(:nth-child(10)) {
        border-right: #76eafd8c 1px solid;
    }

    /* Explicitly place each tool in the respective cell */
    .layout-check {
        grid-column: 1;
        grid-row: 2;
    }

    div.toolbar-section.tool-1 {
        grid-column: 1;
        grid-row: 4;

    }

    div.toolbar-section.tool-2 {
        grid-column: 4;
        grid-row: 4;
    }

    div.toolbar-section.tool-3 {
        grid-column: 3;
        grid-row: 2;
    }

    div.toolbar-section.tool-4 {
        grid-column: 3;
        grid-row: 4;
    }

    div.toolbar-section.tool-5 {
        grid-column: 4;
        grid-row: 2;
    }

    .vrPresets {
        grid-column: 4;
        grid-row: 2;
    }

    div.toolbar-section.tool-6 {
        grid-column: 5;
        grid-row: 2;
        border-right: none;
    }

    #end-space {
        grid-column: 5;
        grid-row: 4;
    }

    #toggleThumbailLocation {
        grid-column: 5;
        grid-row: 4;
    }
}

#active-tools {
    color: #73777e;
    text-align: left;
}

#active-tools svg {
    stroke: #858b95;
    fill: #858b95;
}

.layout-svg {
    margin-top: 2px;
    padding-right: 7px;
    padding-left: 7px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 4px;
}

.layout-svg:hover {
    fill: #76ebfdec;
    color: #76ebfdec;
    stroke: #76ebfdec;
    background: #0e111a;
}



#layout-hover {
    border: 1px solid rgb(98, 113, 146);
    padding: 2px;
    border-radius: 5px;
    margin-top: 140px;
    margin-left: 21px;
    background: #162a4a;
    position: absolute;
    z-index: 10000;

    opacity: 1;
    animation-name: fadeInOpacity;
    animation-duration: .3s;

    display: grid;
    grid-template-columns: repeat(8, auto);
    grid-row: repeat(4, auto);
    ;
    grid-column-gap: 2px;
    grid-row-gap: 2px;

}

@media screen and (orientation: portrait) {
    #layout-hover {
        margin-top: 170px;
        margin-left: 85px;

        grid-template-columns: repeat(3, auto);
        grid-row: repeat(5, auto);
    }


}

.box {
    background-color: #333;
    padding: 10px;
    border-radius: 2px;
    color: #fff;
    align-items: center;
    border: 1px solid rgb(117, 114, 114);
}

.box:hover {
    background-color: rgb(117, 114, 114);
}

.box-hover {
    background-color: rgb(117, 114, 114);
}

#layout-text {
    margin-bottom: 5px;
    color: #858b95;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.svg-tooltip {
    display: block;
    position: absolute;
    z-index: 1000;
    background: #0e111a;
    color: #76ebfdec;
    width: 100px;
    height: 29px;
    padding-top: 10px;
    border: 1px solid #76ebfdec;
    border-radius: 3px;
    left: 235px;
    top: 47px;
    font-size: 14px;
    transform: scale(1);
}


@media screen and (orientation: portrait) {
    .svg-tooltip {
        left: 115px
    }
}

.layout-icon:hover .svg-tooltip {
    display: block !important;
}

.svg-tooltip::before {
    left: 46px;
    bottom: 39px;
    border: 9px solid;
    border-color: transparent transparent rgb(110 218 236) transparent;
    z-index: 10000;
    content: ' ';
    position: absolute;
}

.layout-icon {
    padding-top: 13px;
}

.selectAll {
 color: #d4d4d4;
 display: flex;
 align-items: center;
 justify-content: center;
 width: 32px;
 height: 28px;
 border-radius: 15%;
}

.selectAll:hover {
    fill: #76ebfdec;
    color: #76ebfdec;
    background: #0e111a;
}

#layout {
    margin-bottom: auto;
    margin-top: auto;
    display: flex;
    justify-content: center;
    place-content: space-evenly;
}

#ip-toolbar {
    color: #d4d4d4;
    margin-left: 10px;
}


.nav-container {
    padding: 1em;
    display: block;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    left: -12px;
}

ul,
li {
    list-style: none;
    -webkit-padding-start: 0;
}

a {
    text-decoration: none;
    color: #d4d4d4;
}

.nav-item {
    padding: 1em;
    display: inline;
}

.nav-item-dropdown {
    position: relative;
}

.nav-item-dropdown:hover>.dropdown-menu {
    display: block;
    opacity: 1;
}

.dropdown-trigger {
    position: relative;
}

.dropdown-trigger:focus+.dropdown-menu {
    display: block;
    opacity: 1;
}

.dropdown-trigger::after {
    content: "›";
    position: absolute;
    color: #d4d4d4;
    font-size: 24px;
    font-weight: bold;
    transform: rotate(90deg);
    top: -6px;
    right: -19px;
}

.dropdown-menu {
    background-color: #d4d4d4;
    color: #0e111a;
    display: inline-block;
    text-align: right;
    position: absolute;
    top: 2.5rem;
    right: -10px;
    display: none;
    opacity: 0;
    transition: opacity 0.5s ease;
    width: max-content;
    z-index: 10000;
    cursor: pointer;
}

.dropdown-menu a {
    color: #0e111a;
}

.dropdown-menu-item {
    cursor: pointer;
    padding: 1em;
    text-align: center;
}

.dropdown-menu-item:hover {
    background-color: #2c6787;
}

.dropdown-menu-item:hover a {
    color: #d4d4d4;
}

.no-related {
    min-width: 150px;
}

.hide {
    display: none;
}

.inactive {
    opacity: 0.5;
    pointer-events: none;
}
</style>
