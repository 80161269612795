<template>
    <div id="logo-container">
        <!-- <a href="https://www.medicalconnections.co.uk/" target="_blank" onclick=""> <img v-if="!this.Expanded"
              src="../../assets/logo-large.svg" width="135" alt="company-logo" /></a>
      <a href="https://www.medicalconnections.co.uk/" target="_blank" onclick=""> <img v-if="this.Expanded"
              src="../../assets/favicon.png" width="45" alt="company-logo" /></a> -->
        <div class="logo-name">{{ store?.logo }}</div>
    </div>
</template>

<script>
import { useUIStore } from '../../store/ui';

export default {
    name: 'SidebarLogo',
    components: {
    },
    computed: {
        Expanded() {
            const store = useUIStore();
            return store.expanded;
        }
    },
}
</script>

<style scoped>
#logo-container {
    text-align: center;
    width: 100%;
    height: 100%;
    grid-row-start: 1;
    grid-row-end: 4;
    grid-column-start: 1;
    grid-column-end: 2;
    filter: brightness(1.1);
    /* border-bottom: 1px solid #55748f; */
    filter: invert(10%);
    font-size: 16px;
    color: rgb(221 229 233);
    background: rgb(41 149 189 / 60%);
    top: 20px;
    border-right: 1px solid #51869c;
}

.logo-name {
    padding-top: 14px;
}

img:hover {
    transition: all 0.5s ease;
    filter: contrast(120%);
    filter: invert(20%);
}

.saturate {
    filter: saturate(3);
}

.grayscale {
    filter: grayscale(100%);
}

.contrast {
    filter: contrast(160%);
}

.brightness {
    filter: brightness(0.25);
}

.blur {
    filter: blur(3px);
}

.invert {
    filter: invert(100%);
}

.sepia {
    filter: sepia(100%);
}

.huerotate {
    filter: hue-rotate(180deg);
}

.rss.opacity {
    filter: opacity(50%);
}
</style>
