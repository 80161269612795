<template>
    <div class="canvas-container">
        <div v-if="!preDataLoaded" id="viewer-placeholder">
            <div id="viewer-placeholder-text">Volume Loading</div>
            <div id="dots"></div>
        </div>
        <div class="canvas" id="canvas-1" oncontextmenu="return false;"></div>
    </div>
</template>

<script>
//oncontextmenu="return false;"
import { ref, onMounted, toRef, computed, watch } from "vue";
import * as cornerstoneTools from '@cornerstonejs/tools';
import { RenderingEngine, Enums, setVolumesForViewports, utilities as csUtils, Types, EVENTS, volumeLoader, getRenderingEngine, utilities, CONSTANTS } from "@cornerstonejs/core";
import * as cornerstone3D from "@cornerstonejs/core";
import {
    createImageIdsAndCacheMetaData,
    setCtTransferFunctionForVolumeActor,
} from "../../utils/demo/helpers";
import { useToolsStore } from '../store/tools';
import { useConfigStore } from '../store/config';
import { useMainStore } from '../store/main';
import { useUIStore } from '../store/ui';


export default {
    name: "VR",
    props: {
        canvasData: Object,
    },
    setup(props) {
        const root = ref(null);
        const data = toRef(props, 'canvasData');
        const mainStore = useMainStore();
        let preDataLoaded = ref(false);

        const preset = computed(() => {
            return mainStore.selectedPreset;
        })

        const renderingEngineId = 'VRRenderingEngine';
        const viewportId = 'VR_VIEWPORT';

        watch(preset, (newPreset, oldPreset) => {
            console.log(newPreset);

            let renderingEngine;
            if (cornerstone3D.getRenderingEngine(renderingEngineId)) {
                renderingEngine = cornerstone3D.getRenderingEngine(renderingEngineId);
                const viewport = renderingEngine.getViewport(viewportId);
                const volumeActor = renderingEngine
                    .getViewport(viewportId)
                    .getDefaultActor().actor;

                utilities.applyPreset(
                    volumeActor,
                    CONSTANTS.VIEWPORT_PRESETS.find((preset) => preset.name === newPreset)
                );

                viewport.render();
            }
        })

        onMounted(async () => {
            const toolStore = useToolsStore();
            const config = useConfigStore();
            const mainStore = useMainStore();
            const UIstore = useUIStore();

            mainStore.layoutBefore3D = mainStore.currentLayout;
            UIstore.SetLayoutRowCol("1x1");

            const {
                ToolGroupManager,
                Enums: csToolsEnums,
                synchronizers,
                SynchronizerManager,
                WindowLevelTool,
                TrackballRotateTool
            } = cornerstoneTools;

            const { MouseBindings } = csToolsEnums;
            const { ViewportType } = Enums;
            const studyUID = mainStore.currentStudy;
            const seriesUID = mainStore.selectedSeries.seriesUID;
            console.log("SELECTED " + mainStore.selectedSeries.seriesUID)
            const volumeName = 'CT_VOLUME_ID'; // Id of the volume less loader prefix
            const volumeLoaderScheme = 'cornerstoneStreamingImageVolume'; // Loader id which defines which volume loader to use
            const volumeId = `${volumeLoaderScheme}:${seriesUID}`; // VolumeId with loader id + volume id
            const viewportId = 'VR_VIEWPORT';
            const toolGroupId = toolStore.toolGroups.vr.id;
            const canvasElement1 = document.getElementById("canvas-1");
            const renderingEngineId = 'VRRenderingEngine';

            const vp = {
                viewportId: viewportId,
                element: canvasElement1,
                type: ViewportType.VOLUME_3D,
                defaultOptions: {
                    orientation: Enums.OrientationAxis.CORONAL,
                    background: [0.2, 0, 0.2],
                }
            }

            let loadedStudy = mainStore.VRLastStudyUID === studyUID;
            let loadedSeries = mainStore.VRLastSeriesUID === seriesUID;
            let imageIds;
            let renderingEngine;
            let volume;
            let loaded = false;

            /**
             * If study was previously loaded skip fetching data from server
             */
            if (loadedStudy && loadedSeries) {
                imageIds = mainStore.VRImageIdsCache;
                volume = mainStore.VRVolumeCache;
                if (imageIds && volume) {
                    loaded = true;
                }
            }

            if (!loaded) {
                imageIds = await createImageIdsAndCacheMetaData({
                    StudyInstanceUID:
                        studyUID,
                    SeriesInstanceUID:
                        seriesUID,
                    wadoRsRoot: config.wadoRoot,
                    type: 'VOLUME',
                });

                mainStore.VRImageIdsCache = imageIds;
                try {
                    volume = await volumeLoader.createAndCacheVolume(volumeId, {
                        imageIds,
                    });
                } catch (err) {
                    alert("Could not create volume for series")
                    mainStore.vrMode = false;
                    document.getElementsByClassName(
                        "VR-3D"
                    )[0].parentElement.style.border = "1px dashed transparent";
                    mainStore.VRVolumeCache = null;
                    mainStore.VRLastStudyUID = "";
                    mainStore.VRLastSeriesUID = "";
                    return
                }


                mainStore.VRVolumeCache = volume;
                mainStore.VRLastStudyUID = studyUID;
                mainStore.VRLastSeriesUID = seriesUID;
            }

            if (cornerstone3D.getRenderingEngine(renderingEngineId)) {
                renderingEngine = cornerstone3D.getRenderingEngine(renderingEngineId);
            } else {
                renderingEngine = new RenderingEngine(renderingEngineId);
            }
            renderingEngine.enableElement(vp);

            try {
                // Set the volume to load
                volume.load();
            } catch (err) {
                alert("Could not load volume for series");
                mainStore.vrMode = false;
                document.getElementsByClassName(
                    "VR-3D"
                )[0].parentElement.style.border = "1px dashed transparent";
                mainStore.VRVolumeCache = null;
                mainStore.VRLastStudyUID = "";
                mainStore.VRLastSeriesUID = "";
                return
            }


            var toolGroupVR = ToolGroupManager.getToolGroup(toolGroupId);
            //wwwc
            toolGroupVR.setToolActive(WindowLevelTool.toolName, {
                bindings: [{
                    mouseButton: MouseBindings.Secondary,
                },],
            });
            //rotate
            toolGroupVR.addTool(TrackballRotateTool.toolName, {
                configuration: { volumeId },
            });
            toolGroupVR.setToolActive(TrackballRotateTool.toolName, {
                bindings: [
                    {
                        mouseButton: MouseBindings.Primary, // Left Click
                    },
                ],
            });

            toolGroupVR.addViewport(viewportId, renderingEngineId);

            setVolumesForViewports(renderingEngine, [{ volumeId }], [viewportId])
                .then(() => {
                    const volumeActor = renderingEngine
                        .getViewport(viewportId)
                        .getDefaultActor().actor;

                    utilities.applyPreset(
                        volumeActor,
                        CONSTANTS.VIEWPORT_PRESETS.find((preset) => preset.name === 'CT-Bone')
                    );

                    const renderer = viewport.getRenderer();
                    debugger;
                    renderer.getActiveCamera().elevation(-70);
                    viewport.setCamera({ parallelScale: 600 });

                    viewport.render();
                })
                .catch(err => console.log(err.message));

            let viewport = renderingEngine.getViewport(viewportId);
            renderingEngine.render();
            preDataLoaded.value = true;
        });

        return {
            root,
            data,
            preDataLoaded,
            preset,
        };
    },
    beforeMount() {
    },
    unmounted() {
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

.canvas {
    width: 100%;
    height: 100%;
}

.canvas-container {
    border: 1px solid rgb(94, 90, 93) !important;
    background: #222122;
}

#viewer-placeholder {
    color: #9ca6b6;
    background: #222122;
    font-size: xx-large;
    opacity: 0.8;
    border-radius: 2px;

    align-self: center;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    position: fixed;
    z-index: 100;
}

#viewer-placeholder-text {
    opacity: 0.8;
    margin-right: 24px;
}

#dots {
    width: 60px;
    aspect-ratio: 2;
    --_g: no-repeat radial-gradient(farthest-side, #9ca6b6 90%, #0000);
    background:
        var(--_g) 0 50%,
        var(--_g) 50% 50%,
        var(--_g) 50% 50%,
        var(--_g) 100% 50%;
    background-size: 25% 50%;
    animation: d2 1s infinite linear;
}

.hide {
    display: none;
}

@keyframes d2 {
    33% {
        background-position: 0 0, 50% 100%, 50% 100%, 100% 0
    }

    66% {
        background-position: 50% 0, 0 100%, 100% 100%, 50% 0
    }

    100% {
        background-position: 50% 50%, 0 50%, 100% 50%, 50% 50%
    }
}
</style>
