<template>
    <div class="canvas-container">
        <div class="canvas" id="canvas-1" oncontextmenu="return false;"></div>
    </div>
    <div class="canvas-container">
        <div class="canvas" id="canvas-2" oncontextmenu="return false;"></div>
    </div>
    <div class="canvas-container">
        <div class="canvas" id="canvas-3" oncontextmenu="return false;"></div>
    </div>
</template>

<script>
//oncontextmenu="return false;"
import { ref, onMounted, toRef } from "vue";
import * as cornerstoneTools from '@cornerstonejs/tools';
import { RenderingEngine, Enums, setVolumesForViewports, utilities as csUtils, EVENTS, volumeLoader, Types, getRenderingEngine } from "@cornerstonejs/core";
import * as cornerstone3D from "@cornerstonejs/core";
import {
    createImageIdsAndCacheMetaData,
    setCtTransferFunctionForVolumeActor,
} from "../../utils/demo/helpers";
import { useToolsStore } from '../store/tools';
import { useConfigStore } from '../store/config';
import { useMainStore } from '../store/main';
import { useUIStore } from '../store/ui';

export default {
    name: "MPR",
    props: {
        canvasData: Object,
    },
    setup(props) {
        const root = ref(null);
        const data = toRef(props, 'canvasData');

        onMounted(async () => {

            const toolStore = useToolsStore();
            const config = useConfigStore();
            const mainStore = useMainStore();
            const UIstore = useUIStore();

            mainStore.layoutBefore3D = mainStore.currentLayout;
            if (window.matchMedia("(orientation: landscape)").matches) {
                UIstore.SetLayoutRowCol("1x3");
            }
            else {
                UIstore.SetLayoutRowCol("3x1");
            }

            const toolGroupId = toolStore.toolGroups.volume.id;
            const voiSynchronizerId = 'VOI_SYNCHRONIZER_MPR';
            const viewportId1 = 'CT_AXIAL';
            const viewportId2 = 'CT_SAGITTAL';
            const viewportId3 = 'CT_CORONAL';

            const {
                ToolGroupManager,
                Enums: csToolsEnums,
                synchronizers,
                SynchronizerManager
            } = cornerstoneTools;

            const { createCameraPositionSynchronizer, createVOISynchronizer } =
                synchronizers;

            const { MouseBindings } = csToolsEnums;
            const { ViewportType } = Enums;




            const studyUID = mainStore.mprStudyUID;
            const seriesUID = mainStore.mprSeriesUID;
            console.log("SELECTED " + mainStore.selectedSeries.seriesUID)
            const volumeName = 'CT_VOLUME_ID'; // Id of the volume less loader prefix
            const volumeLoaderScheme = 'cornerstoneStreamingImageVolume'; // Loader id which defines which volume loader to use
            const volumeId = `${volumeLoaderScheme}:${seriesUID}`; // VolumeId with loader id + volume id

            const canvasElement1 = document.getElementById("canvas-1");
            const canvasElement2 = document.getElementById("canvas-2");
            const canvasElement3 = document.getElementById("canvas-3");

            const renderingEngineId = 'MPRRenderingEngine';

            console.log(seriesUID)
            // Get Cornerstone imageIds and fetch metadata into RAM

            let loadedStudy = mainStore.VRLastStudyUID === studyUID;
            let loadedSeries = mainStore.VRLastSeriesUID === seriesUID;
            let imageIds;
            let renderingEngine;
            let volume;
            let loaded = false;

            if (loadedStudy && loadedSeries) {
                imageIds = mainStore.VRImageIdsCache;
                volume = mainStore.VRVolumeCache;
                if (imageIds && volume) {
                    loaded = true;
                }
            }

            if (!loaded) {
                imageIds = await createImageIdsAndCacheMetaData({
                    StudyInstanceUID:
                        studyUID,
                    SeriesInstanceUID:
                        seriesUID,
                    wadoRsRoot: config.wadoRoot,
                    type: 'VOLUME',
                });

                mainStore.VRImageIdsCache = imageIds;

                try {
                    volume = await volumeLoader.createAndCacheVolume(volumeId, {
                        imageIds,
                    });
                } catch (err) {
                    alert("Could not create volume for series")
                    mainStore.mprMode = false;
                    document.getElementsByClassName("MPR-3D")[0].parentElement.style.border = "1px dashed transparent";
                    mainStore.mprStudyUID = "";
                    mainStore.mprSeriesUID = "";
                    return
                }


                mainStore.VRVolumeCache = volume;
                mainStore.VRLastStudyUID = studyUID;
                mainStore.VRLastSeriesUID = seriesUID;
            }

            const viewportInputArray = [{
                viewportId: viewportId1,
                element: canvasElement1,
                type: ViewportType.ORTHOGRAPHIC,
                defaultOptions: {
                    orientation: Enums.OrientationAxis.AXIAL,
                    background: [0, 0, 0],
                },
            },

            {
                viewportId: viewportId2,
                element: canvasElement2,
                type: ViewportType.ORTHOGRAPHIC,
                defaultOptions: {
                    orientation: Enums.OrientationAxis.SAGITTAL,
                    background: [0, 0, 0],
                },
            }, {
                viewportId: viewportId3,
                element: canvasElement3,
                type: ViewportType.ORTHOGRAPHIC,
                defaultOptions: {
                    orientation: Enums.OrientationAxis.CORONAL,
                    background: [0, 0, 0],
                },
            }];


            if (!cornerstone3D.getRenderingEngine(renderingEngineId))
                renderingEngine = new RenderingEngine(renderingEngineId);
            else
                renderingEngine = cornerstone3D.getRenderingEngine(renderingEngineId)

            renderingEngine.setViewports(viewportInputArray);

            try {
                volume.load();
            } catch (err) {
                alert("Could not create volume for series")
                mainStore.mprMode = false;
                document.getElementsByClassName("MPR-3D")[0].parentElement.style.border = "1px dashed transparent";
                mainStore.mprStudyUID = "";
                mainStore.mprSeriesUID = "";
                return
            }

            if (!SynchronizerManager.getSynchronizer(voiSynchronizerId)) {
                createVOISynchronizer(voiSynchronizerId);
            }
            await setVolumesForViewports(
                renderingEngine,
                [
                    {
                        volumeId,
                        callback: setCtTransferFunctionForVolumeActor,
                    },
                ],
                [viewportId1, viewportId2, viewportId3]
            );

            renderingEngine.renderViewports([viewportId1, viewportId2, viewportId3]);



            var toolGroupVolume = ToolGroupManager.getToolGroup(toolGroupId);
            toolGroupVolume.addViewport(viewportId1, renderingEngineId);
            toolGroupVolume.addViewport(viewportId2, renderingEngineId);
            toolGroupVolume.addViewport(viewportId3, renderingEngineId);
            toolGroupVolume.setToolDisabled(cornerstoneTools.CrosshairsTool.toolName);
            toolGroupVolume.setToolActive(cornerstoneTools.CrosshairsTool.toolName, {
                bindings: [{ mouseButton: MouseBindings.Primary }],
            });

            // As the Stack Scroll mouse wheel is a tool using the `mouseWheelCallback`
            // hook instead of mouse buttons, it does not need to assign any mouse button.
            toolGroupVolume.setToolActive(cornerstoneTools.StackScrollMouseWheelTool.toolName);



            //--- MIP, MINIP, AVG ---//

            //DEFAULT
            const blendModeOptions = {
                MIP: 'Maximum Intensity Projection',
                MINIP: 'Minimum Intensity Projection',
                AIP: 'Average Intensity Projection',
            };

            var ipContainer = document.getElementById("ip-toolbar");

            var selectedValue = ipContainer.children[0].value;
            let blendModeToUse;
            switch (selectedValue) {
                case blendModeOptions.MIP:
                    blendModeToUse = Enums.BlendModes.MAXIMUM_INTENSITY_BLEND;
                    break;
                case blendModeOptions.MINIP:
                    blendModeToUse = Enums.BlendModes.MINIMUM_INTENSITY_BLEND;
                    break;
                case blendModeOptions.AIP:
                    blendModeToUse = Enums.BlendModes.AVERAGE_INTENSITY_BLEND;
                    break;
                default:
                    throw new Error('undefined orientation option');
            }

            const crosshairsInstance = toolGroupVolume.getToolInstance(
                cornerstoneTools.CrosshairsTool.toolName
            );

            const oldConfiguration = crosshairsInstance.configuration;

            crosshairsInstance.configuration = {
                ...oldConfiguration,
                slabThicknessBlendMode: blendModeToUse,
            };
            // Update the blendMode for actors to instantly reflect the change
            toolGroupVolume.viewportsInfo.forEach(({ viewportId, renderingEngineId }) => {
                const renderingEngine = getRenderingEngine(renderingEngineId);
                const viewport = renderingEngine.getViewport(viewportId);
                const synchronizer =
                    SynchronizerManager.getSynchronizer(voiSynchronizerId);
                synchronizer.add({ renderingEngineId, viewportId });
                viewport.setBlendMode(blendModeToUse);
                viewport.render();
            });



            //ON CHANGE
            var ipSelectElement = ipContainer.children[0];
            ipSelectElement.addEventListener("change", function (e) {
                //DEFAULT
                const blendModeOptions = {
                    MIP: 'Maximum Intensity Projection',
                    MINIP: 'Minimum Intensity Projection',
                    AIP: 'Average Intensity Projection',
                };

                var ipContainer = document.getElementById("ip-toolbar");
                var selectedValue = ipContainer.children[0].value;
                let blendModeToUse;
                switch (selectedValue) {
                    case blendModeOptions.MIP:
                        blendModeToUse = Enums.BlendModes.MAXIMUM_INTENSITY_BLEND;
                        break;
                    case blendModeOptions.MINIP:
                        blendModeToUse = Enums.BlendModes.MINIMUM_INTENSITY_BLEND;
                        break;
                    case blendModeOptions.AIP:
                        blendModeToUse = Enums.BlendModes.AVERAGE_INTENSITY_BLEND;
                        break;
                    default:
                        throw new Error('undefined orientation option');
                }

                const crosshairsInstance = toolGroupVolume.getToolInstance(
                    cornerstoneTools.CrosshairsTool.toolName
                );

                const oldConfiguration = crosshairsInstance.configuration;

                crosshairsInstance.configuration = {
                    ...oldConfiguration,
                    slabThicknessBlendMode: blendModeToUse,
                };
                // Update the blendMode for actors to instantly reflect the change
                toolGroupVolume.viewportsInfo.forEach(({ viewportId, renderingEngineId }) => {
                    const renderingEngine = getRenderingEngine(renderingEngineId);
                    const viewport = renderingEngine.getViewport(viewportId);

                    viewport.setBlendMode(blendModeToUse);
                    viewport.render();
                });
            });

        });

        return {
            root,
        };
    },
    beforeMount() {

    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

.canvas {
    width: 100%;
    height: 100%;
}

.canvas-container {
    border: 1px solid rgb(94, 90, 93) !important;
    background: #222121;
}
</style>
