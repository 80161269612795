<template>
    <div>
        <select v-model="selectedPreset" @change="handlePresetChange">
            <option v-for="preset in presets" :key="preset.name">{{ preset.name }}</option>
        </select>
    </div>
</template>

<script>
import { CONSTANTS } from '@cornerstonejs/core';
import { useMainStore } from '../../store/main';

export default {
    name: 'VRPresets',
    data() {
        return {
            selectedPreset: 'CT-Bone',
            presets: CONSTANTS.VIEWPORT_PRESETS,
        }
    },
    methods: {
        handlePresetChange() {
            let mainStore = useMainStore();
            mainStore.selectedPreset = this.selectedPreset;
        }
    }
}
</script>

<style scoped>
select {
    /* Reset */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    outline: 0;
    font: inherit;
    /* Personalize */
    width: 185px;
    height: 2em;
    padding: 0 4em 0 1em;
    background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path stroke='grey' fill='grey' d='M6 8l-1 1l5 5l5-5l-1-1l-4 4l-4-4z'/></svg>") no-repeat right 0.8em center/1.4em, linear-gradient(to left, rgba(255, 255, 255, 0.3) 3em, rgba(255, 255, 255, 0.2) 3em);
    color: white;
    border-radius: 0.25em;
    box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    /* <option> colors */
    /* Remove focus outline */
    /* Remove IE arrow */
}

select option {
    color: inherit;
    background-color: #1f3350;
}

select:focus {
    outline: none;
}

select::-ms-expand {
    display: none;
}
</style>