<template>
    <div v-show="isOpen" class="modal">
        <div class="modal-content">
            <!-- HEADER -->
            <div class="bar">
                <div class="heading">
                    <h1>
                        Instance Metadata
                    </h1>
                </div>

                <span class="material-symbols-outlined close" @click="closeModal">
                    close
                </span>
            </div>
            <div class="metadata">
                <p v-for="item in metadataRef" v-bind:key="item[0]">
                    {{ item[0] }}{{ item[2] !== "" ? `: (${item[2]})` : "" }} {{ item[1] }}
                </p>
            </div>
        </div>
    </div>
</template>
  
<script>
import { ref, computed } from 'vue';
import { useUIStore } from '../../store/ui';
import { useMainStore } from '../../store/main';
import { useUtilsStore } from '../../store/utils';

import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader/dist/dynamic-import/cornerstoneWADOImageLoader.min.js";

export default {
    setup() {
        // pinia stores
        const uiStore = useUIStore();
        const main = useMainStore();
        const utils = useUtilsStore();

        // ref variables
        const isOpen = ref(false);
        const metadataRef = ref([]);

        // constants
        const renderingEngineId = 'MCRenderingEngine';

        const openModal = () => {
            isOpen.value = true;
            uiStore.meataDataModalOpen = true;
            metadataRef.value = [];
            populateMetadata();
        };

        const closeModal = () => {
            isOpen.value = false;
            uiStore.meataDataModalOpen = false;
        };

        /**
         * Convert metadata object to a list of lists containing a triple of:
         * 
         * Dicom key, value, vr
         * 
         * Will recursively call itself to build sequence elements which will have their keys appended with ">" 
         * multipled by the sequence level. If no sequence level is provided default to top level (not a sequence)
         * 
         * @param {*} metadata 
         * @param {*} sequenceLevel defaults to 0
         */
        const convertMetadataToList = (metadata, sequenceLevel = 0) => {
            // Repeat ">" based on the sequence level
            const sequenceIndicator = ">".repeat(sequenceLevel);

            // Function to process each value in the metadata
            const processValue = (key, value, vr) => {
                // Check if the value has an Alphabetic property
                if(!value) {
                    metadataRef.value.push([sequenceIndicator + " " + key, "", vr || ""]);
                } else if (value.Alphabetic) {
                    // If so, push it to the metadata reference value
                    metadataRef.value.push([sequenceIndicator + " " + key, value.Alphabetic, vr]);
                } else {
                    // If the value is an object, call the function recursively
                    convertMetadataToList(value, sequenceLevel + 1);
                }
            };

            // Iterate over each key in the metadata
            for (let key in metadata) {
                // Destructure Value and vr from the metadata at the current key
                const { Value, vr } = metadata[key];

                // Continue to next iteration if vr is 'UN' and Value is not defined, or if key is '7FE00010'
                if (vr === 'UN' && !Value || key === '7FE00010') continue;

                // If Value is not defined, push the key and vr to the metadata reference value
                if (!Value) {
                    metadataRef.value.push([sequenceIndicator + " " + key, "", vr || ""]);
                    continue;
                }

                // If vr is 'SQ', push the key and vr to the metadata reference value
                if (vr === 'SQ') {
                    metadataRef.value.push([sequenceIndicator + " " + key, " ", vr]);
                    metadataRef.value.push([sequenceIndicator + ">", "----------------", ""]);
                }


                // If Value is an array and contains an object, process each value
                if (Array.isArray(Value) && Value.some(x => typeof x === 'object' && x !== null && x !== undefined )) {
                    Value.forEach(value => processValue(key, value, vr));
                } else if (Value.length == 1) {
                    // If Value has only one item, push it to the metadata reference value
                    metadataRef.value.push([sequenceIndicator + " " + key, Value[0], vr]);
                } else {
                    // If Value has more than one item, join them with '\\' and push to the metadata reference value
                    let valueStr = Value.join('\\');
                    if (key === '00200037') {
                        const imagePlane = utils.getImagePlaneFromVectorCosines(Value);
                        valueStr = `(${imagePlane}) ` + valueStr;
                    }
                    metadataRef.value.push([sequenceIndicator + " " + key, valueStr, vr]);
                }

                // If vr is 'SQ', push a separator to the metadata reference value
                if (vr === 'SQ') {
                    metadataRef.value.push([">".repeat(sequenceLevel + 1), "----------------", ""]);
                }
            }
        };

        /**
         * Populate metadata values for selected dicom instance
         */
        const populateMetadata = () => {
            const mutltiSelecting = document.getElementsByClassName("multi-selected");

            if (mutltiSelecting.length > 1) {
                alert("Image information not supported when multiple viewports are selected");
                closeModal();
                return
            }

            let canvasElement;
            if (mutltiSelecting.length == 0) {
                const selectedSeriesElement = main.selectedSeriesElement;
                if (!selectedSeriesElement) {
                    alert("No series selected, cannot display metadata");
                    closeModal();
                    return
                }

                const parentNode = selectedSeriesElement.parentNode;

                canvasElement = parentNode ? parentNode.parentNode : null;

            } else {
                canvasElement = mutltiSelecting[0].querySelector('[data-viewport-uid]');
            }

            if (!canvasElement) {
                alert("Could not find selected canvas, cannot display metadata");
                closeModal();
                return;
            }

            const viewportId = canvasElement.attributes['data-viewport-uid'] ? canvasElement.attributes['data-viewport-uid'].value : null;
            const renderingEngine = utils.getRenderingEngine(renderingEngineId);

            try {
                const viewport = renderingEngine.getViewport(viewportId);
                const currentImageId = viewport.getCurrentImageId();

                const metadata = cornerstoneWADOImageLoader.wadors.metaDataManager.get(currentImageId);
                convertMetadataToList(metadata);
                return
            } catch (err) {
                console.log(err)
                alert("failed to retrieve metadata");
                closeModal();
                return
            }
        }

        return {
            isOpen,
            metadataRef,
            openModal,
            closeModal,
            populateMetadata,
        };
    },
};
</script>
  
<style scoped>
.modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    color: rgb(185, 183, 183);
}

.modal-content {
    background-color: #fefefe;
    margin: 3% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 650px;
    height: 770px;
    background-color: rgb(29 46 72);
}

@media screen and ((orientation: portrait)) {
    .modal-content {
        margin: 15% auto;
    }
}

.close {
    font-size: 40px !important;
    color: white;
}

.close:hover {
    color: crimson;
}

.bar {
    display: flex;
    justify-content: space-between;
    height: 30px;
    width: 100%;
    align-items: center;
    margin: 0;
    padding: 0;
}

.heading {
    display: flex;
}

.metadata {
    overflow: auto;
    display: block;
    max-height: 740px;
}

.metadata p {
    text-align: left;
    color: white;
}

.metadata::-webkit-scrollbar {
    width: 9px;
}

.metadata::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #76ebfd54;
}

.modal::-webkit-scrollbar {
    width: 9px;
}

.modal::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #76ebfd54;
}
</style>