<template>
    <div v-if="thumbnailVertical" :id="thumbnailLeft ? 'viewer-content' : 'viewer-content-right'"
        oncontextmenu="return false;" @click.right.prevent="handleContextMenu">
        <div v-if="preDataLoaded" :id="thumbnailLeft ? 'viewer-container' : 'viewer-container-right'">
            <div :id="thumbnailLeft ? 'thumbnails' : 'thumbnails-right'" v-if="preDataLoaded">
                <Thumbnail :thumbnailData="thumbnails" />
            </div>
            <div :id="thumbnailLeft ? 'canvases' : 'canvases-right'"
                v-if="preDataLoaded && this.thumbnailsLoaded && !this.mprMode && !this.vrMode">
                <div v-for="(canvas, index) in canvases" v-bind:key="'index:' + index + ' series:' + canvas.seriesUID" class="canvas-container"
                    style="position:relative;">
                    <Stack :canvasData="canvas" :id='"canvas-" + canvas.id' />
                </div>
            </div>
            <div :id="thumbnailLeft ? 'canvases' : 'canvases-right'" v-if="this.mprMode">
                <MPR />
            </div>
            <div :id="thumbnailLeft ? 'canvases' : 'canvases-right'" v-if="this.vrMode">
                <VR />
            </div>
        </div>

        <div id="viewer-placeholder" v-if="!preDataLoaded">
            <div id="viewer-placeholder-text">No Images Loaded</div>
            <div id="dots"></div>
        </div>
    </div>

    <div v-else :id="!thumbnailBottom ? 'viewer-content-top' : 'viewer-content-bottom'" oncontextmenu="return false;"
        @click.right.prevent="handleContextMenu">
        <div :id="!thumbnailBottom ? 'viewer-container-top' : 'viewer-container-bottom'" v-if="preDataLoaded">
            <div :id="!thumbnailBottom ? 'thumbnails-top' : 'thumbnails-bottom'" v-if="preDataLoaded">
                <ThumbnailH v-bind:thumbnailData="thumbnails" />
            </div>
            <div :id="!thumbnailBottom ? 'canvases-top' : 'canvases-bottom'"
                v-if="preDataLoaded && this.thumbnailsLoaded && !this.mprMode && !this.vrMode">
                <div v-for="(canvas, index) in canvases" v-bind:key="'index:' + index + ' series:' + canvas.seriesUID" class="canvas-container"
                    style="position:relative;">
                    <Stack v-bind:canvasData="canvas" :id='"canvas-" + canvas.id' />
                </div>
            </div>
            <div :id="!thumbnailBottom ? 'canvases-top' : 'canvases-bottom'" v-if="this.mprMode">
                <MPR />
            </div>
            <div :id="!thumbnailBottom ? 'canvases-top' : 'canvases-bottom'" v-if="this.vrMode">
                <VR />
            </div>
        </div>

        <div id="viewer-placeholder" v-if="!preDataLoaded">
            <div id="viewer-placeholder-text">No Images Loaded</div>
            <div id="dots"></div>
        </div>
    </div>

    <MetadataModal ref="meatadataModalRef" />
</template>

<script>
// component imports
import Thumbnail from './Thumbnail.vue';
import ThumbnailH from './ThumbnailH.vue';
import Stack from './Stack.vue';
import MPR from './MPR.vue';
import VR from './VR.vue';
import ContextMenu from '@imengyu/vue3-context-menu';
import MetadataModal from './ui/MetadataModal.vue';

// store imports
import { useMainStore } from '../store/main';
import { useUIStore } from '../store/ui';
import { useToolsStore } from '../store/tools';
import { useConfigStore } from '../store/config';
import { useEventsStore } from '../store/events';
import { useUtilsStore } from '../store/utils';

// vue imports
import { ref, onMounted, onUpdated, toRef, computed } from "vue";

// cornerstone imports
import * as cornerstone3D from "@cornerstonejs/core";

export default {
    name: 'ViewerContent',
    components: {
    Thumbnail,
    ThumbnailH,
    Stack,
    MPR,
    VR,
    MetadataModal
},
    data() {
        return {
        }
    },
    computed: {
        thumbnailBottom() {
            const uiStore = useUIStore();
            return uiStore.thumbnailBottom;
        },
        thumbnailVertical() {
            const uiStore = useUIStore();
            return uiStore.thumbnailVertical;
        },
        thumbnailLeft() {
            const uiStore = useUIStore();
            return uiStore.thumbnailLeft;
        },
        thumbnailsLoaded() {
            const store = useMainStore();
            //If thumbnails should load first then images, use the code below
            //return store.allThumbnailsLoaded;
            return true;
        },
        imagesLoaded() {
            const store = useMainStore();
            return store.imagesLoaded;
        },

        mprMode() {
            const store = useMainStore();
            return store.mprMode;
        },
        vrMode() {
            const store = useMainStore();
            return store.vrMode;
        },
        thumbnails() {
            const configStore = useConfigStore();
            const mainStore = useMainStore();
            var thumbs = [];

            if (!configStore.showRelatedStudies) {
                thumbs.push(mainStore.studiesLoaded[mainStore.selectedStudy - 1]);
            } else {
                mainStore.studiesLoaded.forEach(study => {
                    thumbs.push(study);
                });
            }

            return thumbs;
        },
        layout() {
            const store = useMainStore();
            return store.currentLayout;
        },

    },
    setup(props) {
        // pinia stores
        const mainStore = useMainStore();
        const UIstore = useUIStore();

        // ref variables
        const modalRef = ref(null);
        const meatadataModalRef = ref(null);
        const show = ref(false);
        const optionsComponent = ref({
            zIndex: 3,
            minWidth: 230,
            x: 500,
            y: 200
        })

        // computed 
        const preDataLoaded = computed(() => {
            return mainStore.preDataLoaded;
        })

        document.onkeydown = ((event) => {
            const eventStore = useEventsStore();
            eventStore.KeyboardEvent(event);
        });

        /**
         * When changing back from 3D reset layout to previous state
         */
        const resetLayoutToBefore3D = () => {
            // UIstore.SetLayoutRowCol(mainStore.layoutBefore3D);
            UIstore.firstLayoutLoad = true;
            mainStore.currentLayout = mainStore.layoutBefore3D;
            mainStore.layoutBefore3D = "";
        }

        /**
         * Update canvases object in main store with new stack data
         * 
         * @param {*} study  // study data
         * @param {*} series // series data
         * @param {*} index // mainStore.canvases[index]
         */
        const updateStackCanvas = (study, series, index) => {
            var seriesCanvas = {
                id: index + 1,
                type: "STACK",
                modality: series.modality,
                seriesUID: series.seriesUID,
                studyUID: study.studyUID,
                imageIds: series.imageIds
            }
            mainStore.canvases[index] = seriesCanvas;
        }

        /**
         * Update canvases object in main store with empty stack data
         * 
         * @param {*} index mainStore.canvases[index]
         */
        const updateEmptyCanvas = (index) => {
            let emptyCanvas = {
                id: index + 1,
                type: "EMPTY",
                seriesUID: "",
                studyUID: "",
                imageIds: []
            }
            mainStore.canvases[index] = emptyCanvas;
        }

        const currentLayoutMax = computed(() => {
            return parseInt(mainStore.currentLayout.split('x')[0]) * parseInt(mainStore.currentLayout.split('x')[1]);
        })

        const canvases = computed(() => {
            var study = mainStore.studiesLoaded[mainStore.selectedStudy - 1];

            var totalSeries = study.series;
            let resetFrom3D = false;
            if (mainStore.layoutBefore3D !== "") {
                resetLayoutToBefore3D();
                resetFrom3D = true;
            }

            // first time load. fill the main store canvases object
            if (mainStore.firstCanvasLoad) {
                    for (let index = 0; index < mainStore.canvases.length; index++) {
                        if (index < totalSeries.length) {
                            const series = totalSeries[index];
                            updateStackCanvas(study, series, index);
                        } else {
                            updateEmptyCanvas(index);
                        }
                    }

                mainStore.firstCanvasLoadDone();
            } else {
                if (!mainStore.focusSeries) {
                    for (let index = 0; index < mainStore.canvases.length; index++) {
                        var canvas = mainStore.canvases[index];

                        //refresh study and totalseries object as it can be from different study if it is a prior
                        study = mainStore.studiesLoaded.filter(x => x.studyUID == canvas.studyUID)[0];
                        if (study)
                            totalSeries = study.series;
                        if (canvas.seriesUID != "") {
                            const series = totalSeries.filter(x => x.seriesUID == canvas.seriesUID)[0];
                            updateStackCanvas(study, series, index);
                        } else {
                            updateEmptyCanvas(index);
                        }
                    }
                }
            }
            var canvases = [];
            if (mainStore.focusSeries) {
                var focusedCanvas = {
                    id: 1,
                    type: "STACK",
                    seriesUID: mainStore.focusedSeriesUID,
                    studyUID: mainStore.currentStudy,
                    imageIds: []
                }
                canvases.push(focusedCanvas);
            } else {
                canvases = mainStore.canvases.slice(0, currentLayoutMax.value);
            }

            if (!resetFrom3D) {
                UIstore.SetLayoutRowCol(mainStore.currentLayout);
            }
            return canvases;
        })

        /**
         * Using context menu plugin!
         * 
         * Open context menu on ctrl + right click
         * 
         * Item 1 - Save current layout as hanging protocol
         * 
         * Item 2 - View metadata of selected instance
         * 
         * @param {*} e mouse event
         */
        const handleContextMenu = (e) => {
            if (!e.ctrlKey) return
            //prevent the browser's default menu
            e.preventDefault();
            //show your menu
            ContextMenu.showContextMenu({
                customClass: "context-menu",
                x: e.x, // open at current mouse position
                y: e.y,
                items: [
                    {
                        label: "View Image Information",
                        onClick: () => {
                            meatadataModalRef.value.openModal();
                        }
                    },
                ]
            });
        }

        return {
            canvases,
            currentLayoutMax,
            preDataLoaded,
            show,
            optionsComponent,
            modalRef,
            meatadataModalRef,
            handleContextMenu,
        }

    },
}
</script>

<style scoped>
.context-menu {
    border-radius: 0 !important;
    box-shadow: none !important;
    border: 1px solid #585858 !important;
    color: rgb(110 218 236);
    background-color: rgb(29 46 72) !important;
    padding: 0 !important;
}

#viewer-content {
    background-color: #162a4a;
    grid-row-start: 2;
    grid-row-end: 5;
    grid-column-start: 1;
    grid-column-end: 5;
    display: grid;
    grid-template-columns: 240px auto;
    grid-template-rows: calc(100vh - 51px);
}

#viewer-container {
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 1;
    grid-column-end: 3;
    display: grid;
    grid-template-columns: 240px auto;
    grid-template-rows: auto;
}

@media screen and (orientation: portrait) {
    #viewer-content {
        grid-template-columns: 190px auto;
        grid-template-rows: calc(100vh - 81px);
    }

    #viewer-container {
        grid-template-columns: 190px auto;
    }
}

@media screen and ((orientation: landscape) and (max-width: 1680px)) {
    #viewer-content {
        grid-template-columns: 190px auto;
        grid-template-rows: calc(100vh - 51px);
    }

    #viewer-container {
        grid-template-columns: 190px auto;
    }
}

#canvases {
    background: rgb(70, 67, 67);

    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 2;
    grid-column-end: 3;
    width: 100%;
    height: 100%;


    display: grid;
    /* grid-template-columns: repeat(1, auto);
  grid-template-rows: repeat(1, auto); */
}

#viewer-content-bottom {
    background-color: #162a4a;
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 1;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: calc(100vh - 51px) 260px;
}

#viewer-container-bottom {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 1;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto 260px;
}

/* @media screen and (orientation: portrait) {
    #viewer-content-bottom {
        grid-template-rows: calc(100vh - 81px) 260px;
    }

    #viewer-container-bottom {
        grid-template-rows: auto 260px;
    }
} */

#canvases-bottom {
    background: rgb(70, 67, 67);

    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 1;
    grid-column-end: 1;
    width: 100%;
    height: 100%;


    display: grid;
    /* grid-template-columns: repeat(1, auto);
  grid-template-rows: repeat(1, auto); */
}

#thumbnails-bottom {
    /* background: #9ca6b6; */
    /* border: 2px solid #b4bcc9;
  border-radius: 3px; */

    grid-row-start: 2;
    grid-row-end: 3;

    display: grid;

    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;


    /* grid-template-columns: 240px;
    grid-template-rows: 120px auto; */
}

#viewer-content-top {
    background-color: #162a4a;
    grid-row-start: 2;
    grid-row-end: 3;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 260px calc(100vh - 51px - 261px);
}

#viewer-container-top {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 1;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 260px auto;
}

#canvases-top {
    background: rgb(70, 67, 67);
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 1;
    width: 100%;
    height: calc(100vh - 51px - 261px);


    display: grid;
    /* grid-template-columns: repeat(1, auto);
  grid-template-rows: repeat(1, auto); */
}

#thumbnails-top {
    /* background: #9ca6b6; */
    /* border: 2px solid #b4bcc9;
  border-radius: 3px; */

    grid-row-start: 1;
    grid-row-end: 2;

    display: grid;

    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;


    /* grid-template-columns: 240px;
    grid-template-rows: 120px auto; */
}

@media screen and (orientation: portrait) {
    #canvases-top {
        width: 100%;
        height: calc(100vh - 81px - 260px);

    }

    #viewer-content-top {
        grid-template-rows: 260px calc(100vh - 81px - 261px);
    }

    #viewer-container-top {
        grid-template-rows: 260px auto;
    }

}

#viewer-placeholder {
    grid-row-start: 2;
    grid-row-end: 1;
    grid-column-start: 1;
    grid-column-end: 5;

    color: #9ca6b6;
    font-size: xx-large;
    opacity: 0.8;
    border-radius: 2px;

    align-self: center;
    width: max-content;
    margin-left: auto;
    margin-right: auto;

    display: flex;
}

#viewer-placeholder-text {
    opacity: 0.8;
    margin-right: 24px;
}

#thumbnails {
    /* background: #9ca6b6; */
    /* border: 2px solid #b4bcc9;
  border-radius: 3px; */

    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 1;
    grid-column-end: 2;

    display: grid;

    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;


    /* grid-template-columns: 240px;
    grid-template-rows: 120px auto; */
}


#thumbnails::-webkit-scrollbar {
    width: 9px;
}

#thumbnails::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #76ebfd54;
}

#thumbnails-bottom::-webkit-scrollbar {
    width: 9px;
}

#thumbnails-bottom::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #76ebfd54;
}

#thumbnails-top::-webkit-scrollbar {
    width: 9px;
}

#thumbnails-top::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #76ebfd54;
}

#viewer-content-right {
    background-color: #162a4a;
    grid-row-start: 2;
    grid-row-end: 5;
    grid-column-start: 1;
    grid-column-end: 5;
    display: grid;
    grid-template-columns: auto 240px;
    grid-template-rows: calc(100vh - 51px);
}

#viewer-container-right {
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 1;
    grid-column-end: 3;
    display: grid;
    grid-template-columns: auto 240px;
    grid-template-rows: auto;
}

@media screen and (orientation: portrait) {
    #viewer-content-right {
        grid-template-columns: auto 190px;
        grid-template-rows: calc(100vh - 81px);
    }

    #viewer-container-right {
        grid-template-columns: auto 190px;
    }

}

@media screen and ((orientation: landscape) and (max-width: 1680px)) {
    #viewer-content-right {
        grid-template-columns: auto 190px;
        grid-template-rows: calc(100vh - 51px);
    }

    #viewer-container-right {
        grid-template-columns: auto 190px;
    }

}

#canvases-right {
    background: rgb(70, 67, 67);

    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 1;
    grid-column-end: 2;
    width: 100%;
    height: 100%;


    display: grid;
    /* grid-template-columns: repeat(1, auto);
  grid-template-rows: repeat(1, auto); */
}

#thumbnails-right {
    /* background: #9ca6b6; */
    /* border: 2px solid #b4bcc9;
  border-radius: 3px; */

    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 2;
    grid-column-end: 3;

    display: grid;

    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;


    /* grid-template-columns: 240px;
    grid-template-rows: 120px auto; */
}

#thumbnails-right::-webkit-scrollbar {
    width: 9px;
}

#thumbnails-right::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #76ebfd54;
}

.canvas-container {
    border: 1px solid rgb(90, 91, 94) !important;
}

.canvas-container.selected {
    border: 1px solid #76eafdb8 !important;
}

.canvas-container.multi-selected {
    border: 1px solid #f88a30b8 !important;
}

.metadata-loading {}

#dots {
    width: 60px;
    aspect-ratio: 2;
    --_g: no-repeat radial-gradient(farthest-side, #000 90%, #0000);
    background:
        var(--_g) 0 50%,
        var(--_g) 50% 50%,
        var(--_g) 50% 50%,
        var(--_g) 100% 50%;
    background-size: 25% 50%;
    animation: d2 1s infinite linear;
    display: none;
}

#dots-2 {
    width: 60px;
    aspect-ratio: 2;
    --_g: no-repeat radial-gradient(farthest-side, #9da5b3 90%, #0000);
    background:
        var(--_g) 0 50%,
        var(--_g) 50% 50%,
        var(--_g) 50% 50%,
        var(--_g) 100% 50%;
    background-size: 25% 50%;
    animation: d2 1s infinite linear;
}

@keyframes d1 {
    to {
        clip-path: inset(0 -34% 0 0)
    }
}

@keyframes d2 {
    33% {
        background-position: 0 0, 50% 100%, 50% 100%, 100% 0
    }

    66% {
        background-position: 50% 0, 0 100%, 100% 100%, 50% 0
    }

    100% {
        background-position: 50% 50%, 0 50%, 100% 50%, 50% 50%
    }
}
</style>
