<template>
  <div v-for="(thumbnail, index) in thumbnailData" v-bind:key="index" class="thumbnail-container">
    <!-- <div v-if="index == 1" id="related-header">
            <hr v-if="index == 1" />
            <div class="related-title">
                Related Studies
            </div>
            <hr v-if="index == 1" />
            <div class="related-title-arrows">
                <svg width="26" height="26" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 13L12 5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 13L12 19L16 13L8 13Z" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <svg width="26" height="26" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 13L12 5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 13L12 19L16 13L8 13Z" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <svg width="26" height="26" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 13L12 5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 13L12 19L16 13L8 13Z" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>
        </div> -->

    <div class="study-thumbnail" v-if="index == 0">
      <div class="study-thumbnail-info">
        <div class="patient-name" v-if="index == 0">
          {{ PatientName(thumbnail) }}
        </div>
        <div class="study-date">Study Date: {{ StudyDate(thumbnail) }}</div>
        <div class="study-date">Study Time: {{ StudyTime(thumbnail) }}</div>
        <div class="patient-dob" v-if="index == 0">
          DOB: {{ PatientDOB(thumbnail) }}
        </div>
        <div class="patient-age">Age: {{ PatientAge(thumbnail) }}</div>
        <div class="modality">
          Modality: <span>{{ ModalitiesInStudy(thumbnail) }}</span>
        </div>
        <div class="instituion-name" v-if="index == 0">
          From: {{ SentFrom == "" ? "" : SentFrom }}
        </div>
        <div class="number-images">
          Total Images: {{ NumberOfImages(thumbnail) }}
        </div>
        <br />
        <div class="study-desc">{{ StudyDescription(thumbnail) }}</div>
      </div>
    </div>
    <div class="study-thumbnail study-thumbnail-related" v-if="index != 0">
      <div class="study-thumbnail-info study-thumbnail-info-related">
        <div class="patient-name" v-if="index == 0">
          {{ PatientName(thumbnail) }}
        </div>
        <div class="study-date">Study Date: {{ StudyDate(thumbnail) }}</div>
        <div class="study-date">Study Time: {{ StudyTime(thumbnail) }}</div>
        <div class="patient-dob" v-if="index == 0">
          DOB: {{ PatientDOB(thumbnail) }}
        </div>
        <div class="patient-age">Age: {{ PatientAge(thumbnail) }}</div>
        <div class="modality">
          Modality: <span>{{ ModalitiesInStudy(thumbnail) }}</span>
        </div>
        <div class="instituion-name" v-if="index == 0">
          From: {{ SentFrom == "" ? "" : SentFrom }}
        </div>
        <div class="number-images">
          Total Images: {{ NumberOfImages(thumbnail) }}
        </div>
        <br />
        <div class="study-desc">{{ StudyDescription(thumbnail) }}</div>
      </div>
    </div>

    <div class="series-thumbnail-container" v-if="index == 0">
      <div v-for="(s, index) in Series(thumbnail)" v-bind:key="index" :class="getThumbClass()" v-bind:series="index"
        v-bind:study="thumbnail.studyUID">
        <div :class="getThumbImgClass()" draggable="true" v-on:dragstart="onDragStart"
          v-on:dblclick="FocusCanvasThumbnail(s, $event)"></div>
        <div class="series-thumbnail-info">
          <div class="series-info">
            <span class="seriesuid" style="display: none">{{
              s.seriesUID
            }}</span>
            <span class="series-number">S:{{ s.seriesNumber }}</span>
            <div class="series-imagenumber">
              <div class="series-imagenumber-svg">
                <svg width="18px" height="18px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M 2 5 L 2 23 L 6 23 L 6 27 L 30 27 L 30 9 L 26 9 L 26 5 Z M 4 7 L 24 7 L 24 21 L 4 21 Z M 6 9 L 6 19 L 22 19 L 22 9 Z M 8 11 L 20 11 L 20 17 L 8 17 Z M 26 11 L 28 11 L 28 25 L 8 25 L 8 23 L 26 23 Z" />
                </svg>
              </div>
              <div class="series-imagenumber-text">
                {{ s.seriesRelatedInstances }}
              </div>
            </div>
          </div>
          <div class="series-description">{{ s.seriesDescription }}</div>
        </div>
      </div>
    </div>

    <div class="series-thumbnail-container series-thumbnail-container-related" v-if="index != 0">
      <div v-for="(s, index) in Series(thumbnail)" v-bind:key="index" :class="getThumbClass()" v-bind:series="index"
        v-bind:study="thumbnail.studyUID">
        <div :class="getThumbImgClass()" draggable="true" v-on:dragstart="onDragStart"
          v-on:dblclick="FocusCanvasThumbnail(s, $event)"></div>
        <div class="series-thumbnail-info series-thumbnail-info-related">
          <div class="series-info">
            <span class="seriesuid" style="display: none">{{
              s.seriesUID
            }}</span>
            <span class="series-number">S:{{ s.seriesNumber }}</span>
            <div class="series-imagenumber">
              <div class="series-imagenumber-svg">
                <svg width="18px" height="18px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M 2 5 L 2 23 L 6 23 L 6 27 L 30 27 L 30 9 L 26 9 L 26 5 Z M 4 7 L 24 7 L 24 21 L 4 21 Z M 6 9 L 6 19 L 22 19 L 22 9 Z M 8 11 L 20 11 L 20 17 L 8 17 Z M 26 11 L 28 11 L 28 25 L 8 25 L 8 23 L 26 23 Z" />
                </svg>
              </div>
              <div class="series-imagenumber-text">
                {{ s.seriesRelatedInstances }}
              </div>
            </div>
          </div>
          <div class="series-description">{{ s.seriesDescription }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, toRef, computed } from "vue";
import { useUIStore } from "../store/ui";
import { useEventsStore } from "../store/events";
import { useUtilsStore } from "../store/utils";
export default {
  name: "Thumbnail",
  props: {
    thumbnailData: Object,
  },
  setup(props) {
    const root = ref(null);
    const data = toRef(props, "thumbnailData");

    // pinia stores
    const events = useEventsStore();
    const utils = useUtilsStore();
    const uiStore = useUIStore();

    // computed variables
    const thumbnailSize = computed(() => {
      return uiStore.thumbnailHSize;
    })

    const thumbnailBottom = computed(() => {
      return uiStore.thumbnailBottom;
    })

    const thumbnailLeft = computed(() => {
      return uiStore.thumbnailLeft;
    })

    const SentFrom = computed(() => {
      return uiStore.centerName;
    })

    // METHODS
    const getThumbClass = () => {
      return utils.getThumbClass(thumbnailSize.value);
    }

    const getThumbImgClass = () => {
      return utils.getThumbImgClass(thumbnailSize.value);
    }

    const handleThumbnailOrientation = (location) => {
      utils.handleThumbnailOrientation(location);
    }

    const handleMoveThumbnail = () => {
      utils.handleMoveThumbnail();
    }

    const onDragStart = (e) => {
      events.thumbnailDragStart(e);
    }

    const FocusCanvasThumbnail = (s, e) => {
      events.FocusCanvasThumbnail(s, e);
    }

    const GetAgeFromDOB = (dob) => {
      return utils.GetAgeFromDOB(dob);
    }

    const PatientName = (thumbnail) => {
      return thumbnail.patientName;
    }

    const PatientAge = (thumbnail) => {
      return utils.PatientAge(thumbnail);
    }

    const PatientDOB = (thumbnail) => {
      return utils.PatientDOB(thumbnail);
    }

    const StudyDescription = (thumbnail) => {
      return thumbnail.studyDescription;
    }

    const ModalitiesInStudy = (thumbnail) => {
      return thumbnail.modalitiesInStudy;
    }

    const StudyDate = (thumbnail) => {
      return utils.StudyDate(thumbnail);
    }

    const StudyTime = (thumbnail) => {
      return utils.StudyTime(thumbnail);
    }

    const NumberOfImages = (thumbnail) => {
      return utils.NumberOfImages(thumbnail);
    }

    const Series = (thumbnail) => {
      return thumbnail.series;
    }

    onMounted(async () => {
      var thumbnails = data.value;
      await events.thumbnailsOnMounted(thumbnails);
      
      //mainStore.allThumbnailsLoaded = true;
    });

    return {
      root,
      thumbnailSize,
      thumbnailBottom,
      thumbnailLeft,
      SentFrom,
      Series,
      NumberOfImages,
      getThumbClass,
      getThumbImgClass,
      handleThumbnailOrientation,
      handleMoveThumbnail,
      StudyTime,
      StudyDate,
      ModalitiesInStudy,
      PatientDOB,
      StudyDescription,
      PatientAge,
      PatientName,
      GetAgeFromDOB,
      FocusCanvasThumbnail,
      onDragStart,
    };
  },
};
</script>

<style scoped>
.study-thumbnail-info-related {
  margin-bottom: 5px !important;
}

.study-thumbnail-info-related {
  color: #ffdb98de;
}

.series-thumbnail-info-related {
  color: #ffdb98de !important;
}

.study-thumbnail-related {
  color: #dbbd83de;
}

.series-thumbnail-container-related {
  margin-bottom: 50px;
}

#related-header hr {
  border: 1px solid #dbbd83de;
  width: 80%;
}

#related-header {
  margin-top: 60px;
}

.related-title {
  font-size: 20px;
  text-align: center;
  color: #2b2828;
  background: #dbbe83b2;
}

.related-title-arrows {
  text-align: center;
  fill: #ffdb98de;
  stroke: #ffdb98de;
}

#by-view {}

#echo-layout-dropdown {
  display: flex;
  margin: 29px;
  margin-top: 21px;
}

#echo-layout-change {
  margin-bottom: 15px;
  text-align-last: center;
}

#echo-layout-change button {
  margin-right: 14px;
  background: rgb(22 42 74);
  box-shadow: #dbdbdf52 0 1px 2px 1px inset;
  color: #d6d6e7;
  border-radius: 3px;
  width: 70px;
  height: 29px;
  margin-left: 17px;
}

.button-style-1 {
  align-items: center;
  appearance: none;
  background-color: #fcfcfd;
  border-radius: 4px;
  border-width: 0;
  box-sizing: border-box;
  color: #36395a;
  cursor: pointer;
  display: inline-flex;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.1s, transform 0.1s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 14px;
}

.button-style-1:focus {}

.active-view-stage-button {
  color: rgb(22 42 74) !important;
  box-shadow: #683156 0 5px 5px 1px inset;
  background: #d6d6e7 !important;
}

.button-style-1:hover {
  border: 1px solid #d6d6e7;
}

.button-style-1:active {
  box-shadow: #d6d6e7 0 3px 7px inset;
  transform: translateY(1px);
}

#echo-layout-change button:hover {
  color: #d4d4d4;
  background: rgb(22 42 74);
}

#echo-text {
  margin-right: 21px;
  margin-left: 11px;
}

#echo-select {
  width: -webkit-fill-available;
  margin-right: 5px;
  background: rgb(22 42 74);
  color: #e4e3e3;
  outline: none;
  min-width: 104px;
}

.series-thumbnail {
  margin-bottom: 10px;
  margin-top: 15px;
}

.series-imagenumber svg {
  margin-top: 0px;
}

.study-thumbnail {
  background: rgb(22 42 74);
  color: #d4d4d4;
  /* border-bottom: 1px solid rgb(73, 73, 75); */
  max-height: 240px;
  max-width: 230px;
}

.study-thumbnail:hover {
  /* background: rgb(14, 23, 37);
    color: rgb(216, 209, 209); */
}

.study-thumbnail:last-child {
  border-bottom: 1px solid rgb(73, 73, 75);
}

.series-thumbnail-image {
  width: 55%;
  height: 160px;
  border: 1px solid rgb(90, 91, 94);
  margin-left: auto;
  margin-right: auto;
  border-radius: 3px;
}

.series-thumbnail-image .cornerstone-canvas {
  border-radius: 3px;
}

.series-thumbnail-image:hover {
  opacity: 0.9;
  border: 1px solid rgb(194, 43, 131);
}

.series-thumbnail-info {
  text-align: center;
  color: #d4d4d4;
  fill: rgba(90, 194, 231, 0.651);
  margin-top: 5px;
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(1, auto);
  place-items: center;
}

.series-info {
  display: grid;
  grid-template-rows: repeat(1, auto);
  grid-template-columns: repeat(2, auto);
  width: fit-content;
  max-width: 120px;
}

.series-number {
  grid-row-start: 1;
  grid-row-end: 1;
  grid-column-start: 1;
  margin-right: 40px;
  margin-top: 2px;
}

.series-imagenumber {
  grid-row-start: 1;
  grid-row-end: 1;
  grid-column-start: 2;
  display: flex;
  place-content: center;
}

.series-imagenumber-text {
  margin-left: 4px;
}

.series-description {
  white-space: nowrap;
  overflow: hidden;
  border-left: 1em solid transparent;
  border-right: 1em solid transparent;
  text-overflow: ellipsis;
  width: -webkit-fill-available;
}

.patient-name {
  font-size: 18px;
  margin-top: 2.5px;
  margin-bottom: 2.5px;
  text-align: center;
}

/* @media screen and (orientation: portrait) {
    .patient-name {
        font-size: 18px;
        margin-top: 5px;
        margin-bottom: 0;
    }

    .series-thumbnail-image {
        height: 125px;
    }
} */

.modality {}

.study-date {
  font-size: 14px;
  font-weight: 400;
}

.study-desc {
  /* margin-bottom: 10px;
    height: 50px; */
}

.study-thumbnail-info {
  font-size: 12px;
  margin: 8px;
  margin-bottom: 30px;
  line-height: 1.5;
}

.number-images {}

.series-thumbnail-container {
  color: rgb(216, 209, 209);
  display: inline-flex;
  width: calc(100vw - 250px);
  flex-wrap: wrap;
  overflow-y: scroll;
  /* height: 100%; */
  height: fit-content;
  /* padding-top: 20px; */
  mask-image: linear-gradient(to top, transparent, black),
    linear-gradient(to left, transparent 17px, black 17px);
  mask-size: 100% 20000px;
  mask-position: left bottom;
  -webkit-mask-image: linear-gradient(to top, transparent, black),
    linear-gradient(to left, transparent 17px, black 17px);
  -webkit-mask-size: 100% 20000px;
  -webkit-mask-position: left bottom;
  transition: mask-position 0.3s, -webkit-mask-position 0.3s;
  border-bottom: dotted 0.25px;
  min-height: 260px;
}

.series-thumbnail-container:hover {
  overflow-y: scroll;
  -webkit-mask-position: left top;
}

.series-thumbnail-container::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #76ebfd54;
}

.series-thumbnail-container::-webkit-scrollbar {
  width: 9px;
}

.series-thumbnail-container::-webkit-scrollbar-track {
  /* background-color: #9ca6b6; */
}

.thumbnail-container {
  /* display: contents; */
  display: flex;
}

.arrow {
  position: absolute;
  transform: translateY(-50%);
  fill: #d4d4d4;
  stroke: #d4d4d4;
  color: #d4d4d4;
  cursor: pointer;
}

.arrow:hover {
  background: rgb(59, 106, 182);
  border-radius: 40%;
}

.top-arrow {
  right: 5%;
  top: calc(51px + 210px);
  transform: rotate(-90deg);
  /* Other styles for the arrow icon container */
}

.bottom-arrow {
  right: 5%;
  bottom: 210px;
  transform: rotate(-90deg);
  /* Other styles for the arrow icon container */
}

.left-arrow-bottom {
  right: 6%;
  bottom: 190px;
  transform: rotate(180deg);
}

.right-arrow-bottom {
  right: 4%;
  bottom: 177.5px;
}

.left-arrow-top {
  right: 6%;
  top: calc(51px + 187.5px);
  transform: rotate(180deg);
}

.right-arrow-top {
  right: 4%;
  top: calc(51px + 200px);
}

@media screen and (orientation: portrait) {
  /* .study-thumbnail-info {
        font-size: 12px;
        margin: 8px;
        margin-bottom: 30px;
        line-height: 1.4;
    } */

  .top-arrow {
    top: calc(81px + 160px);
    /* Other styles for the arrow icon container */
  }

  .bottom-arrow {
    bottom: 160px;
    /* Other styles for the arrow icon container */
  }

  .left-arrow-bottom {
    right: 7%;
    bottom: 140px;
  }

  .right-arrow-bottom {
    right: 3%;
    bottom: 128px;
  }

  .left-arrow-top {
    right: 7%;
    top: calc(81px + 139px);
  }

  .right-arrow-top {
    right: 3%;
    top: calc(81px + 150px);
  }
}

.small {
  width: 20%;
  height: auto;
}

.medium {
  width: 25%;
  height: auto;
}

.large {
  width: 33%;
  height: auto;
}

.small-img {
  height: 110px;
}

.medium-img {
  height: 140px;
}

.large-img {
  height: 160px;
}


@media screen and (max-width: 1080px) {
  .small {
    width: 25%;
  }

  .medium {
    width: 33%;
  }

  .large {
    width: 50%;
  }

  .small-img {
    height: 70px;
  }

  .medium-img {
    height: 110px;
  }

  .large-img {
    height: 130px;
  }
}
</style>
